/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import { ChevronRightIcon, StarIcon } from "@heroicons/react/solid";
import Header from "./components/header";
import Footer from "./components/footer";
import { Link } from "react-router-dom";
const tabs = [
  { name: "Kullanım Koşulları", href: "/kullanim-kosullari", current: false },
  {
    name: "Kişisel Verilerin Korunması Politikası",
    href: "/kvkk",
    current: true,
  },
  { name: "Aydınlatma Metni", href: "/aydinlatma-metni", current: false },
  { name: "Gizlilik Politikası", href: "/gizlilik", current: false },
  { name: "Yardım", href: "mailto:info@manjo.app", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function GDPR() {
  return (
    <div>
      <Header></Header>
      <div className="m-12">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? "bg-manjOrange-lightest text-manjOrange"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-manjOrange font-semibold tracking-wide uppercase">
                Manjo
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                KULLANICI KİŞİSEL VERİLERİNİN KORUNMASI POLİTİKASI
              </span>
            </h1>

            <p className="mt-8 text-xl text-gray-500 leading-8">
              1. Giriş 6698 Sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”)
              7 Nisan 2016 tarihinde yürürlüğe girmiştir. Kanun ile kişisel
              verinin tanımı yapılarak, bunların korunmasına ilişkin ilkeler ve
              bu verilerin işlenmesinde veri sorumlusu sıfatı taşıyanların
              uyacakları şartlara yer verilmiştir. İşbu Kişisel Verilerin
              Korunması ve İşlenmesi Politikası, (“Politika”) kişisel verilerin
              işlenmesi ve korunması konusunda yürürlükte bulunan ilgili kanuni
              düzenlemelere göre hazırlanmıştır. Politika Manjo’nun Web
              Sitesinde (https://manjo.app/) yayımlanarak kişisel veri
              sahiplerinin erişimine sunulmaktadır. Değişen şartlara ve mevzuata
              uyum sağlamak amacıyla Politika’da değişiklik ve güncellemeler
              yapılabilecek olup ilgili internet sitesi üzerinden kişisel veri
              sahiplerine sunulabilecektir. İşbu Politika, Manjo Elektronik
              İletişim A.Ş. (“Manjo”) tarafından, Manjo kullanıcılarının
              (“Kullanıcı”) kişisel verilerinin nasıl korunduğu, kişisel
              verileri işlerken uyum sağlanan kurallar ve veri işleme süreçleri
              hakkında bilgiler içermektedir. Kullanıcılar, Kanun uyarınca
              aydınlatma metnine Web Sitesi’nden erişebilirler. Kullanıcılar,
              kişisel verilerinin korunması ve işlenmesine yönelik her türlü
              soru için info@manjo.app e-posta adresi üzerinden iletişime
              geçebilirler.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              2. Kişisel Verilerin İşlenmesi İlkeleri Manjo, Anayasa’nın m.
              20/III kapsamında, kişisel verileri ilgili mevzuatta belirtilen
              ilkeler doğrultusunda veya kişinin açık rızasının bulunduğu
              durumlarda aşağıdaki ilkelere uygun olarak işlemektedir: Hukuka ve
              dürüstlük kurallarına uygun olma: Veri işleme faaliyetleri mevzuat
              düzenlemelerine göre ve etik kurallara uygun şekilde yürütülüyor.
              Doğru ve gerektiğinde güncel olma: Kişisel verilerin doğru ve
              güncel olmasını sağlamak için gerekli kanallar temin ediliyor.
              Belirli, açık ve meşru amaçlar için işlenme: Kişisel verilerin
              hangi amaçlarla doğrultusunda işleneceğini belirleniyor ve bu
              amaçlar şeffaf ve anlaşılır bir biçimde bilginize sunuluyor.
              İşleme amaçlarıyla bağlantılı, sınırlı ve ölçülü olma: İlgili
              olmayan veya gerek duyulmayan kişisel veriler işlenmiyor, işlenen
              kişisel veriler minimumda tutulmaya özen gösteriliyor. İlgili
              mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre
              kadar muhafaza edilme: Kişisel verilerin saklanması için mevzuatta
              öngörülmüş bir süre varsa bu süreye uygunluk sağlanıyor, aksi
              taktirde kişisel veriler sadece gereken süre boyunca saklanıyor.
              Kişisel veriler işlenirken yukarıda belirtilen veri işleme
              ilkelerine uygunluk sağlanıyor.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3. Kişisel Veri İşleme Şart ve Amaçları Aşağıda anılan şartlardan
              biri veya birden fazlasının varlığı halinde kişisel veriler,
              sahibinin açık rızası alınmaksızın işlenebilecektir. Kişisel
              veriler, aşağıda belirtilen Kanun’un 4. 5. ve 6. maddelerindeki
              hukuki sebeplere dayalı olarak işleniyor. Genel nitelikli kişisel
              veriler ile ilgili olarak; - Kişisel verilerinizin işlenmesine
              ilişkin Manjo’nun ilgili faaliyette bulunmasının Kanunlarda açıkça
              öngörülmesi - Manjo tarafından kişisel veri işleme faaliyetinde
              bulunulmasının kişisel veri sahibinin ya da bir başkasının hayatı
              veya beden bütünlüğünün korunması için zorunlu olması ve bu
              durumda da kişisel veri sahibinin fiili veya hukuki geçersizlik
              nedeniyle rızasını açıklayamayacak durumda bulunması - Kişisel
              verilerinizin Manjo tarafından işlenmesinin bir sözleşmenin
              kurulması veya ifasıyla doğrudan doğruya ilgili ve gerekli olması
              - Kişisel verilerinizin işlenmesinin Manjo’nun hukuki
              yükümlülüğünü yerine getirebilmesi için zorunlu olması - Kişisel
              verilerinizin sizler tarafından alenileştirilmiş olması şartıyla;
              sizlerin alenileştirme amacıyla sınırlı bir şekilde Manjo
              tarafından işlenmesi - Kişisel verilerinizin Manjo tarafından
              işlenmesinin Manjo’nun veya sizlerin veya üçüncü kişilerin
              haklarının tesisi, kullanılması veya korunması için zorunlu olması
              - Sizlerin temel hak ve özgürlüklerine zarar vermemek kaydıyla
              Manjo’nun meşru menfaatleri için kişisel veri işleme faaliyetinde
              bulunulmasının zorunlu olması Bu kapsamda kişisel veriler Manjo
              tarafından aşağıdaki amaçlarla işlemektedir: - Kullanıcı
              ilişkileri yönetimi süreçlerinin planlanması ve icrası, -
              Kullanıcı memnuniyeti aktivitelerinin planlanması ve icrası -
              Kullanıcı talep ve/veya şikayetlerinin takibi - Kullanıcıların
              finansal risklerinin tespitine yönelik faaliyetler yapılması -
              Satış sonrası destek hizmetleri aktivitelerinin planlanması
              ve/veya icrası - Şirket denetim faaliyetlerinin planlanması ve
              icrası - Şirket faaliyetlerinin şirket prosedürleri ve/veya ilgili
              mevzuata uygun olarak yürütülmesinin temini için gerekli
              operasyonel faaliyetlerinin planlanması ve icrası - Şirket
              operasyonlarının güvenliğinin temini - Şirketin sunduğu ürün veya
              hizmetlerden en yüksek faydanın elde edilmesi için ilgili
              süreçlerin planlanması ve icrası - Sözleşme süreçlerinin ve/veya
              hukuki taleplerin takibi - Stratejik planlama faaliyetlerinin
              icrası - Üretim ve/veya operasyon süreçlerinin planlanması ve
              icrası - Ürün ve/veya hizmetlerin pazarlama ve satış süreçlerinin
              ve pazar araştırması faaliyetlerinin planlanması ve icrası - Bilgi
              güvenliği süreçlerinin planlanması, denetimi ve icrası - Bilgi
              teknolojileri alt yapısının oluşturulması ve yönetilmesi -
              Çalışanların Kullanıcı bilgisine erişim yetkilerinin planlanması
              ve icrası - Finans ve/veya muhasebe işlerinin takibi - Hukuk
              işlerinin takibi - İş faaliyetlerinin etkinlik/verimlilik ve/veya
              yerindelik analizlerinin gerçekleştirilmesi faaliyetlerinin
              planlanması ve/veya icrası - İş sürekliliğinin sağlanması
              faaliyetlerinin planlanması ve/veya icrası - Kurumsal iletişim
              faaliyetlerinin planlanması ve icrası - Lojistik faaliyetlerinin
              planlanması ve icrası - Restoranlar, üye işyerleri ve iş ortakları
              ve/veya tedarikçilerin bilgiye erişim yetkilerinin planlanması,
              icrası ve ilişkilerin yönetimi - Verilerin doğru ve güncel
              olmasının sağlanması - Yetkili kuruluşlara mevzuattan kaynaklı
              bilgi verilmesi
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4. Kişisel Verilerin Aktarılması Kanun’un 8. ve 9. maddesinde
              kişisel verilerin yurt içinde ve yurt dışına aktarılmasına ilişkin
              hususlara yer verilmiştir. Manjo, hukuka uygun olarak elde etmiş
              olduğu kişisel verileri veri işleme amaçları doğrultusunda gerekli
              güvenlik önlemlerini alarak veri sahibinin kişisel verilerini/
              özel nitelikli kişisel verilerini üçüncü kişilere
              aktarabilmektedir. Bu doğrultuda Manjo kişisel verileri Bölüm 3’te
              belirtilen işleme şartlarından ve aşağıda belirtilen şartlardan
              birinin varlığı halinde üçüncü kişilere aktarabilecektir: -
              Kişisel veri sahibinin açık rızası var ise, - Kanunlarda kişisel
              verinin aktarılacağına ilişkin açık bir düzenleme var ise, -
              Kişisel veri sahibinin veya başkasının hayatı veya beden
              bütünlüğünün korunması için zorunlu ise ve kişisel veri sahibi
              fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda ise
              veya rızasına hukuki geçerlilik tanınmıyorsa; - Bir sözleşmenin
              kurulması veya ifasıyla doğrudan doğruya ilgili olmak kaydıyla
              sözleşmenin taraflarına ait kişisel verinin aktarılması gerekli
              ise, - Manjo’nun hukuki yükümlülüğünü yerine getirmesi için
              kişisel veri aktarımı zorunlu ise, - Kişisel veriler, kişisel veri
              sahibi tarafından alenileştirilmiş ise, - Kişisel veri aktarımı
              bir hakkın tesisi, kullanılması veya korunması için zorunlu ise, -
              Kişisel veri sahibinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, Manjo’nun meşru menfaatleri için kişisel veri aktarımı
              zorunlu ise. Manjo meşru ve hukuka uygun kişisel veri işleme
              amaçları doğrultusunda kişisel veri sahibinin kişisel verilerini
              aşağıdaki hallerde yurtdışına aktarabilecektir: - Veri sahibinin
              açık rızasının bulunması halinde veya - Veri sahibinin açık rızası
              bulunmadığı ancak yukarıda belirtilen diğer şartlardan bir veya
              birkaçının bulunması halinde; - Verilerin aktarıldığı ülkede
              yeterli koruma bulunması ve - Verilerin aktarıldığı ülkede yeterli
              koruma bulunmaması durumunda Manjo’nun ilgili yabancı ülkedeki
              veri sorumlusu ile birlikte yeterli korumayı yazılı olarak taahhüt
              etmesi ve KVK Kurulu’nun izninin alınması kaydı ile. Manjo
              yukarıda belirtilen şartlar doğrultusunda ve Kanun’un 8. ve 9.
              maddelerine uygun olarak Politika ile yönetilen veri sahiplerinin
              kişisel verilerini aşağıda belirtilen taraflara aktarabilir: - İş
              ortaklığının kurulma amaçlarının yerine getirilmesini temin etme
              amacıyla anonim olarak iş ortaklarına, (başkaca veri aktarımının
              gerekmesi halinde ayrıca açık rıza alınmaktadır.) - Restoranlar ve
              üye işyerleri ile sözleşme kapsamındaki amaçların yerine
              getirilmesi için sınırlı olarak restoranlara ve üye işyerlerine, -
              Manjo’nun tedarikçiden dış kaynaklı olarak temin ettiği ve
              Manjo’nun ticari faaliyetlerini yerine getirmek için gerekli
              hizmetlerin Manjo’ya sunulmasını sağlamak amacıyla sınırlı olarak
              tedarikçilere, - Manjo iştiraklerinin de katılımını gerektiren
              ticari faaliyetlerinin yürütülmesini temin etmekle sınırlı olarak
              iştiraklere, - Manjo’nun ticari faaliyetlerine ilişkin
              stratejilerin tasarlanması, şirket prosedürlerine uygun
              bilgilendirmelerin yapılması ile ilgili mevzuat hükümlerine uygun
              şekilde denetim amaçlarıyla sınırlı olarak hissedarlara, - Hukuki
              yetkileri dahilinde talep ettikleri amaçla sınırlı olarak ilgili
              kamu kurum ve kuruluşları ile özel hukuk kişilerine.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5. Kişisel Verilerin Korunması Kişisel verilerinizin korunmasını
              sağlamak için gerekli teknik ve idari tedbirler alınıyor. Kişisel
              verilerin güvenliğini sağlamak için ilgili mevzuat uyarınca
              öngörülen ve KVK Kurulu tarafından bildirilecek olan diğer idari
              ve teknik önlemleri alarak kişisel verilerin hukuka uygun olarak
              işlenmesi ve korunması sağlanıyor.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6. Veri Sahibinin Aydınlatılması ve Hakları Manjo, Kanun’un 10.
              maddesinde belirtilen kişisel verilerin elde edilmesi sırasında
              kişisel veri sahiplerinin aydınlatılmasına ilişkin veri
              sahiplerini sahip olduğu haklar hakkında bilgilendirmektedir. Veri
              sahibinin sahip olduğu bu haklar aşağıdaki gibidir: - Kişisel
              verilerinin işlenip işlenmediğini öğrenme, - Kişisel verileri
              işlenmişse buna ilişkin bilgi talep etme, - Kişisel verilerinin
              işlenme amacını ve bunların amacına uygun kullanılıp
              kullanılmadığını öğrenme, - Kişisel verilerinizin üçüncü kişilere
              aktarılıp aktarılmadığını öğrenme, - Kişisel verileri üçüncü
              kişilere aktarılıyorsa, yurt içinde veya yurt dışında kişisel
              verilerin aktarıldığı üçüncü kişileri bilme, - Kişisel verilerin
              eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
              isteme ve bu kapsamda yapılan işlemin kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme, - Kanun ve
              ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına
              rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde
              kişisel verilerin silinmesini veya yok edilmesini isteme ve bu
              kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü
              kişilere bildirilmesini isteme, - İşlenen verilerin münhasıran
              otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
              kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, -
              Kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle
              zarara uğraması hâlinde zararın giderilmesini talep etme haklarına
              sahiptir. Ancak Kanun’un 28. maddesi uyarınca aşağıdaki durumlarda
              yukarıda sayılan haklar ileri sürülemez: - Kişisel verilerin resmi
              istatistik ile anonim hâle getirilmek suretiyle araştırma,
              planlama ve istatistik gibi amaçlarla işlenmesi, - Kişisel
              verilerin millî savunmayı, millî güvenliği, kamu güvenliğini, kamu
              düzenini, ekonomik güvenliği, özel hayatın gizliliğini veya
              kişilik haklarını ihlal etmemek ya da suç teşkil etmemek kaydıyla,
              sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade
              özgürlüğü kapsamında işlenmesi, - Kişisel verilerin millî
              savunmayı, millî güvenliği, kamu güvenliğini, kamu düzenini veya
              ekonomik güvenliği sağlamaya yönelik olarak kanunla görev ve yetki
              verilmiş kamu kurum ve kuruluşları tarafından yürütülen önleyici,
              koruyucu ve istihbari faaliyetler kapsamında işlenmesi, – Kişisel
              verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine
              ilişkin olarak yargı makamları veya infaz mercileri tarafından
              işlenmesi. Kanun’un 28/2 maddesi uyarınca; aşağıda belirtilen
              durumlarda kişisel veri sahipleri zararın giderilmesini talep etme
              hakkı hariç olmak üzere, yukarıda belirtilen diğer haklarını ileri
              süremezler: - Kişisel veri işlemenin suç işlenmesinin önlenmesi
              veya suç soruşturması için gerekli olması, - Kişisel veri sahibi
              tarafından kendisi tarafından alenileştirilmiş kişisel verilerin
              işlenmesi, - Kişisel veri işlemenin kanunun verdiği yetkiye
              dayanılarak görevli ve yetkili kamu kurum ve kuruluşları ile kamu
              kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya
              düzenleme görevlerinin yürütülmesi ile disiplin soruşturma veya
              kovuşturması için gerekli olması, - Kişisel veri işlemenin bütçe,
              vergi ve mali konulara ilişkin olarak Devletin ekonomik ve mali
              çıkarlarının korunması için gerekli olması. Kişisel veri
              sahipleri, Kanun çerçevesinde Manjo’dan kişisel verilerine ilişkin
              bilgi talep edebilirler. Kişisel veri sahipleri, Uygulama’ya
              kaydolurken iletilen, Manjo sisteminde kayıtlı e-posta adresinden
              info@manjo.app adresine ulaşarak işlenen kişisel verileri hakkında
              bilgi talep edebilirler.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7. Kişisel Verilerin Çeşitleri İşbu Politika kapsamında, Manjo
              tarafından işlenen kişisel veriler kategorize edilmiştir. Kişisel
              veri sahiplerinin kişisel verileri aşağıda belirtilen şekildedir:
              Kimlik ve İletişim Bilgisi: ad, soyad, telefon, adres, işyeri
              bilgisi, e-posta adresi Kullanıcı Bilgisi, Kullanıcı İşlem Bilgisi
              ve Finansal Bilgi: üyelik bilgileri, üyelik ID numarası, Manjo
              hizmetlerinden yararlandığınız tarih ve saate ilişkin veriler,
              Manjo ile iletişime geçme nedenleriniz, uygulama üzerinde arama
              yaparken kullandığınız terimler ve filtreleme tercihleriniz, puan
              ve yorumlarınız, yemek tercihleriniz, ziyaret ettiğiniz restoran
              kategorileri, kullanım sırasında oluşan hatalar, fatura ve ödeme
              bilgisi Lokasyon Bilgisi: Manjo’nun hizmetlerini kullanmanız
              esnasında elde etmiş olduğumuz, GPS verisi gibi konumunuza ilişkin
              belirli veya yaklaşık konum bilgileri İşlem Güvenliği Bilgisi: log
              in credential bilgileri, cihaz bilgileri, IP adresi Pazarlama
              Bilgisi: alışkanlıklarınız ile beğenilerinizi gösteren raporlar ve
              değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları
              vb. bilgiler Talep/Şikâyet Yönetimi Bilgisi: Uygulama üzerinden
              yaptığınız talep ve şikayetler ile Uygulama’da paylaştığınız
              yorumlarınız
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              8. Kişisel Verilerin Saklanması Kişisel veriler, Manjo tarafından
              ilgili mevzuatta öngörülen süreler boyunca ve hukuki
              yükümlülükleri doğrultusunda saklanmaktadır. Kişisel verilerin ne
              kadar süre boyunca saklanması gerektiğine ilişkin mevzuatta bir
              süre düzenlenmemişse, kişisel veriler Manjo’nun veriyi işlerken
              yürüttüğü faaliyet ile bağlantılı olarak Manjo’nun uygulamaları
              ile ticari teamüller doğrultusunda işlenmesini gerektiren süre
              kadar işlenmekte daha sonra silinmekte, yok edilmekte veya anonim
              hale getirilmektedir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              9. Kişisel Verilerin Silinmesi, Yok Edilmesi ve Anonimleştirilmesi
              Türk Ceza Kanunu’nun 138. maddesinde ve Kanunu’nun 7. maddesinde
              düzenlendiği üzere ilgili kanun hükümlerine uygun olarak işlenmiş
              olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
              kalkması halinde Manjo’nun kararına istinaden veya kişisel veri
              sahibinin talebi üzerine kişisel veriler silinir, yok edilir veya
              anonim hâle getirilir.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
