/* This example requires Tailwind CSS v2.0+ */
export default function Logos() {
  return (
    <div className="bg-gradient-to-b from-manjOrange-shallow to-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-black">
          Partnerlerimiz arasın arasında yerinizi alın
        </h2>
        <div className="flow-root mt-8 lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img
                className="h-24"
                src={process.env.PUBLIC_URL + "/burgerillas.png"}
                alt="Burgerillas"
              />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img
                className="h-24"
                src={process.env.PUBLIC_URL + "/saycheese.png"}
                alt="Say Cheese"
              />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img
                className="h-24"
                src={process.env.PUBLIC_URL + "/bros.png"}
                alt="Bros Burger"
              />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img
                className="h-24"
                src={process.env.PUBLIC_URL + "/dumbo.png"}
                alt="Dumbo"
              />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img
                className="h-24"
                src={process.env.PUBLIC_URL + "/candyshop.png"}
                alt="Candy Shop"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
