/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import { ChevronRightIcon, StarIcon } from "@heroicons/react/solid";
import Footer from "../components/footer";
import Header from "../components/header";
import { Link } from "react-router-dom";

const tabs = [
  { name: "Kullanım Koşulları", href: "/kullanim-kosullari", current: false },
  {
    name: "Kişisel Verilerin Korunması Politikası",
    href: "/kvkk",
    current: true,
  },
  { name: "Aydınlatma Metni", href: "/aydinlatma-metni", current: false },
  { name: "Gizlilik Politikası", href: "/gizlilik", current: false },
  { name: "Yardım", href: "mailto:info@manjo.app", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InviteFriends() {
  return (
    <div>
      <Header />
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-manjOrange font-semibold tracking-wide uppercase">
                Arkadaşını davet et, 10 TL'lİk kupon kazan!
              </span>
              <span className="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Kampanya Bilgileri
              </span>
            </h1>

            <p className="mt-8 text-xl text-gray-500 leading-8">
              Manjo'ya arkadaşlarını davet ederek Manjo cüzdan kredisi
              kazanabilir ve kredilerini vereceğin siparişlerde harcayabilirsin.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Davet ettiğin arkadaşın ilk siparişini verdiği anda cüzdan
              bakiyene 10 TL eklenir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Arkadaşını davet etmek için Manjo uygulamasına gir. Profil
              sekmesine geç ve sağ üstteki "Ayarlar" tuşuna bas. Sonrasında
              "Arkadaşını Davet Et" sekmesine git.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Bu sekmede bulunan link veya QR kod ile arkadaşını davet
              edebilirsin. Dilersen rehberinden de arkadaşlarını davet
              edebilirsin.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Manjo Ekibi
            </p>
            <div className="mt-10 w-full sm:max-w-lg lg:ml-0">
              <div className="flex flex-wrap items-start">
                <div className="flex justify-center m-2 ml-0">
                  <a href="https://manjo.page.link/vo2x">
                    <img
                      className="h-14 sm:h-15"
                      src={process.env.PUBLIC_URL + "/app-store-cta.png"}
                      alt="Tuple"
                    />
                  </a>
                </div>
                <div className="flex justify-center m-2 ml-0">
                  <a href="https://manjo.page.link/vo2x">
                    <img
                      className="h-14 sm:h-15"
                      src={process.env.PUBLIC_URL + "/google-play-cta.png"}
                      alt="Tuple"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
