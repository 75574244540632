import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RestaurantsLandingPage from "./landing_pages/for_restaurants";
import LandingPage from "./landing_pages/main";
import UseTerms from "./landing_pages/terms_of_use";
import GDPR from "./landing_pages/kvkk";
import Privacy from "./landing_pages/privacy";
import Clarification from "./landing_pages/clarification";
import FAQ from "./landing_pages/faq";
import InviteFriends from "./landing_pages/campaigns/invite-friends";
// import Integration from "./landing_pages/integrations/integrations";
import KocUniversityCampaign from "./landing_pages/campaigns/koc-university";

export default function App() {
  return (
    <Router basename="/">
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/restoranlar-icin" component={RestaurantsLandingPage} />
        <Route path="/kullanim-kosullari" component={UseTerms} />
        <Route path="/aydinlatma-metni" component={Clarification} />
        <Route path="/kvkk" component={GDPR} />
        <Route path="/gizlilik" component={Privacy} />
        <Route path="/sikca-sorulan-sorular" component={FAQ} />
        {/* <Route path="/entegrasyon" component={Integration} /> */}
        <Route
          path="/kampanyalar/arkadasini-davet-et"
          component={InviteFriends}
        />
        <Route
          path="/kampanyalar/koc-universitesi"
          component={KocUniversityCampaign}
        />
      </Switch>
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
