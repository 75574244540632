import { Fragment } from "react";
import React from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  BriefcaseIcon,
  CheckCircleIcon,
  DesktopComputerIcon,
  DeviceMobileIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  SpeakerphoneIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  PlayIcon,
  QrcodeIcon,
  ShieldCheckIcon,
  StarIcon,
  UserGroupIcon,
  XIcon,
} from "@heroicons/react/outline";

import { ChevronDownIcon } from "@heroicons/react/solid";
// import { useLocation } from "react-router";
import { Link, useLocation } from "react-router-dom";

const solutions = [
  {
    name: "Manjo Uygulaması",
    description: "Restoranda ağırladığınız müşteriniz marka elçiniz olsun.",
    href: "#",
    icon: DeviceMobileIcon,
  },
  {
    name: "QR Sipariş",
    description: "Girişe ve masalara yerleştirilen QR kodlar ile sipariş alın.",
    href: "#",
    icon: QrcodeIcon,
  },
  {
    name: "Sadakat ve Etkileşim",
    description:
      "Sadakat programı ve sosyal özellikler ile müşterileriniz geri gelsin.",
    href: "#",
    icon: StarIcon,
  },
  {
    name: "Knowledge Base",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: DeviceMobileIcon,
  },
];

const blogPosts = [
  {
    id: 1,
    name: "Boost your conversion rate",
    href: "#",
    preview:
      "Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.",
    imageUrl:
      "https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80",
  },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
    preview:
      "Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.",
    imageUrl:
      "https://images.unsplash.com/1/apple-gear-looking-pretty.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80",
  },
];

export default function Header() {
  const location = useLocation();
  const [banner, setBanner] = React.useState(true);

  console.log("location", location);

  function closeBanner() {
    setBanner(false);
  }
  return (
    <header>
      {banner && (
        <div className="bg-viomanjo">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-viomanjo">
                  <SpeakerphoneIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">
                    Manjo'yu kampüsünde görmek için bize ulaş.
                  </span>
                  <span className="hidden md:inline">
                    Manjo kampüste! Manjo'yu üniversite kampüsünde görmek için
                    hemen bize ulaş.
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://forms.gle/YDgASQyU1DXVypsp8"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                >
                  Bize ulaş
                </a>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  onClick={closeBanner}
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Popover className="relative bg-white">
        {({ open }) => (
          <>
            <div
              className="absolute inset-0 shadow z-30 pointer-events-none"
              aria-hidden="true"
            />
            <div className="relative z-20">
              <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
                <div>
                  <Link to="/" className="flex">
                    <span className="sr-only">Workflow</span>
                    <img
                      className="h-12 w-auto sm:h-14"
                      src={process.env.PUBLIC_URL + "/manjo3.png"}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    {location.pathname == "/restoranlar-icin" && (
                      <Link
                        to="/restoranlar-icin"
                        className="cursor-pointer text-base font-medium text-gray-500"
                      >
                        Restoranlar için
                      </Link>
                    )}
                    {location.pathname != "/restoranlar-icin" && (
                      <Link
                        to="/restoranlar-icin"
                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                      >
                        Restoranlar için
                      </Link>
                    )}
                    {
                      //<MenuIcon className="h-6 w-6" aria-hidden="true" />
                    }
                  </Popover.Button>
                </div>

                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                  <Popover.Group as="nav" className="flex space-x-10">
                    {location.pathname == "/restoranlar-icin" && (
                      <span
                        to="/restoranlar-icin"
                        className="cursor-pointer text-base font-medium text-gray-500"
                      >
                        Restoranlar için
                      </span>
                    )}
                    {location.pathname != "/restoranlar-icin" && (
                      <Link
                        to="/restoranlar-icin"
                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                      >
                        Restoranlar için
                      </Link>
                    )}
                    {/*<Link to="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Ofisler için
                          </Link>*/}
                  </Popover.Group>
                  {location.pathname == "/restoranlar-icin" && (
                    <div className="flex items-center md:ml-12">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://forms.gle/ig6BrA8yEeWtiQpV7"
                        className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-xl shadow-sm text-base font-medium text-white bg-manjOrange hover:bg-manjOrange-dark"
                      >
                        Manjo'ya Katıl
                      </a>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://panel.manjo.app/"
                        className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-manjOrange rounded-xl shadow-sm text-base font-medium text-manjOrange hover:bg-gray-50"
                      >
                        Panel Girişi
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*

                  <Transition
                      show={open}
                      as={Fragment}
                      enter="duration-200 ease-out"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="duration-100 ease-in"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                        focus
                        static
                        className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                    >
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5 sm:pb-8">
                          <div className="flex items-center justify-between">
                            <div>
                              <img
                                  className="h-8 w-auto"
                                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                  alt="Workflow"
                              />
                            </div>
                            <div className="-mr-2">
                              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Close menu</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-6 sm:mt-8">
                            <nav>
                              <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                                {solutions.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                                    >
                                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-manjOrange text-white sm:h-12 sm:w-12">
                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                      </div>
                                      <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                                    </Link>
                                ))}
                              </div>
                              <div className="mt-8 text-base">
                                <Link to="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                  {' '}
                                  View all products <span aria-hidden="true">&rarr;</span>
                                </Link>
                              </div>
                            </nav>
                          </div>
                        </div>
                        <div className="py-6 px-5">
                          <div className="grid grid-cols-2 gap-4">
                            <Link to="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                              Pricing
                            </Link>

                            <Link to="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                              Docs
                            </Link>

                            <Link to="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                              Company
                            </Link>

                            <Link to="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                              Resources
                            </Link>

                            <Link to="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                              Blog
                            </Link>

                            <Link to="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                              Contact Sales
                            </Link>
                          </div>
                          <div className="mt-6">
                            <Link
                                to="#"
                                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                              Sign up
                            </Link>
                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                              Existing customer?{' '}
                              <Link to="#" className="text-indigo-600 hover:text-indigo-500">
                                Sign in
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
*/}
          </>
        )}
      </Popover>
    </header>
  );
}
