/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/

import Header from "./components/header";
import Footer from "./components/footer";
import { Link } from "react-router-dom";
const tabs = [
  { name: "Kullanım Koşulları", href: "/kullanim-kosullari", current: true },
  {
    name: "Kişisel Verilerin Korunması Politikası",
    href: "/kvkk",
    current: false,
  },
  { name: "Aydınlatma Metni", href: "/aydinlatma-metni", current: false },
  { name: "Gizlilik Politikası", href: "/gizlilik", current: false },
  { name: "Yardım", href: "mailto:info@manjo.app", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UseTerms() {
  return (
    <div>
      <Header></Header>
      <div className="m-12">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            //onSelect="this.options[this.selectedIndex].value && (window.location = this.options[this.selectedIndex].value);"
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? "bg-manjOrange-lightest text-manjOrange"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-manjOrange font-semibold tracking-wide uppercase">
                Manjo
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                KULLANIM KOŞULLARI
              </span>
            </h1>

            <p className="mt-8 text-xl text-gray-500 leading-8">1. Giriş</p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              1.1. Koşullar ve Uygulama’da yer alan diğer kurallar Manjo
              tarafından sunulan hizmetlere ilişkin şart ve koşulları ve
              Uygulama’nın kullanılmasına ilişkin kuralları düzenlemektedir.
              Kullanıcı’nın, Uygulama’yı açtığında veya kaydolduğunda Koşulları
              onayladığı ve Uygulama’daki hizmetlerden yararlanmaya başladığı
              andan itibaren Koşullara uymayı taahhüt ettiği kabul edilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              1.2. Manjo, hizmetlerin sürekliliğini sağlama ve ileride doğacak
              teknik zaruretler veya mevzuata uyum sağlanması amacıyla Koşulları
              Kullanıcılar aleyhine olmamak kaydıyla tek taraflı olarak
              değiştirme hakkına sahiptir. Manjo, herhangi bir değişiklik olması
              halinde güncel kullanım şartlarını aynı link altında yeni tarih
              güncellemesi ile Uygulama’da yayınlayacak, gerek görmesi halinde
              elektronik posta veya mobil bildirim ile Kullanıcılarına
              bildirilecek ve onayına sunacaktır. Yenilenmiş güncel Koşullar,
              Uygulama’da yayınlandığı andan itibaren geçerli olacak ve
              Uygulama’nın veya hizmetlerinin kullanımı o andan itibaren
              yenilenmiş Koşullar şartlarına tabi olacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              2. Tanımlar Manjo: Manjo Elektronik İletişim Anonim Şirketi.
              Uygulama: https://manjo.app internet sitesi ve iOS / Android
              tabanlı Mobil Uygulamalar veya Manjo’nun sunduğu ürünlerin veya
              hizmetlerin kullanılabildiği diğer uygulamalar. Kullanıcı:
              Uygulama üzerinden mal veya hizmetlere ilişkin sipariş talebi
              oluşturan kişi. Taraf(lar): Kullanıcı ve/veya Manjo. Koşullar: Bu
              kullanım koşullarının tamamı. Ürün: Manjo’nun hizmet verdiği
              bölgelerde, Uygulama’da yer alan seçenekler arasından, Kullanıcı
              tarafından seçilen ve Manjo tarafından, Kullanıcı’ya sunulan
              hizmet veya mal. Üye İşyeri: Uygulama’da bulunan Ürün’ün gerçek
              sağlayıcısı, tedarikçisi veya satıcısı.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3. Üyelik Sistemi
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3.1. Uygulama’ya üyelik ücretsizdir. Kullanıcı, kayıt için gerekli
              bilgileri ve telefon numarasını sağladıktan sonra Uygulama’ya
              kaydolur. Şifre'nin belirlenmesi ve korunması Kullanıcı’nın kendi
              sorumluluğundadır. Kullanıcı adı ve şifresi paylaşılmamalı ve
              özenle korunmalıdır. Kullanıcı, hesabının kendi kusuru nedeniyle
              başka kişiler tarafından kötü niyetle kullanılmasından doğrudan
              sorumludur. Manjo, bu sebeple ödemek zorunda kalacağı her türlü
              adli/idari para cezası ve/veya tazminat için Kullanıcı’ya aynen
              rücu hakkına sahiptir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3.2. Kullanıcı hesabı oluştururken Uygulama’ya beyan edilen isim,
              adres, telefon, e-posta gibi her türlü bilgi, güncel, doğru ve
              eksiksiz olmalıdır. Söz konusu bilgilerin yanlış veya eksik olması
              nedeniyle Kullanıcıların veya 3. kişilerin uğrayacağı zararlardan
              sorumluluk ilgili Kullanıcı’ya aittir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3.3. Kullanıcı kayıtlı telefon numarasıyla yapacağı her türlü
              işlemden bizzat kendisinin sorumlu olduğunu; kabul, beyan ve
              taahhüt eder. Kullanıcı, Uygulama’ya kayıtlı telefon numarasını
              değiştirmesi durumunda Manjo’ya bildirmelidir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3.4. Üyeliğe ilişkin kullanım hakları, kullanıcı adı ve şifresi
              başkalarına devredilemez.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4. Uygulama’nın Kullanımı
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4.1. Kullanıcı, kayıt için gerekli bilgileri sağladığı ve telefon
              numarasını doğruladığı takdirde Uygulama’ya kaydolabilir ve
              Uygulama’nın sağladığı hizmetlerden yararlanabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4.2. Kullanıcı, Uygulama içinde yer alan Üye İşyerlerini ve
              Ürün’lerini inceleyebilir, Ürünler’i sepetine ekleyebilir ve satın
              alabilir. Kullanıcı, Koşullar çerçevesinde ve Koşullara uyduğu
              takdirde Uygulama’dan hizmet almaya devam edebilecektir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4.3. Kullanıcı, Online Ödeme Yönetimini seçmesi durumunda, bir
              kereye mahsus olmak üzere kredi kartı bilgilerini sisteme girer ve
              bu bilgiler daha sonraki kullanımlarda bir daha ödeme bilgilerini
              girmeyi gerektirmeden kullanılır. Kullanıcı, Uygulama’da birden
              fazla kredi kartı ya da ödeme aracı tanımlayarak seçtiği kredi
              kartı ya da ödeme aracı ile işlem yapabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5. Karşılıklı Hak ve Yükümlülükler
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5.1. Kullanıcı Hak ve Yükümlülükleri:
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              a) Kullanıcı, kayıt olurken verdiği bilgilerin doğru olduğunu, bir
              telefon numarası ile tek hesap açılabileceğini anladığını, verdiği
              telefon numarasını değiştiremeyeceğini, yeni bir telefon numarası
              ile yeni bir hesap açabileceğini, telefonunu değiştirerek yeni bir
              hesap açtığı takdirde eski hesabını kapatması gerektiğinin
              bilincinde olduğunu ve kapatmaz ise tüm sorumluluğun kendisine ait
              olduğunu, kayıt olurken verdiği bilgilerin hatalı olmasından
              kaynaklanan kendisinin ya da üçüncü kişilerin zararına olacak tüm
              yaşananlarda sorumluluğun kendisine ait olduğunu kabul, beyan ve
              taahhüt eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              b) Kullanıcı, Uygulama aracılığıyla Manjo tarafından sunulan
              hizmetlerden yararlanabilmek amacıyla kullandığı sistem erişim
              araçlarının (kullanıcı ismi, şifre v.b.) güvenliği, saklanması,
              üçüncü kişilerin bilgisinden uzak tutulması ile ilgili tüm
              sorumluluğun tamamen kendisine ait olduğunu kabul eder. Kredi
              kartı ya da diğer ödeme araçlarına ilişkin bilgileri 3. kişiler
              ile paylaşmamak, bu bilgileri 3. kişiler tarafından kullanılan
              Uygulama’ya tanımlamamak Kullanıcı’nın sorumluluğundadır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              c) Kullanıcı’nın Uygulama’yı kullandığı cihazını kaybetmesi veya
              cihazın çalınması halinde Manjo’ya bildirimde bulunması ve kartın
              kullanımına son vermesi gerekmektedir. 3. kişiler Uygulama’da
              tanımlı kart bilgilerine erişemezler ancak Uygulama’yı kullanarak
              Ürün siparişi ve ödemesi yapabilirler. Böyle bir durumdan Manjo
              sorumlu tutulamaz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              d) Kullanıcı, siparişini hazırlayan veya hazırlanmasında katkısı
              olan 3. kişilere, Uygulama üzerinden bahşiş ödemesi yapmak
              istemesi halinde, ödeme sayfasında yer alan bahşiş tutarlarından
              birini seçerek ya da istediği tutarı girerek bahşiş ödemesini
              yapabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              e) Ürün, Kullanıcı’nın siparişini verdiği Üye İşyeri’nden teslim
              alınır. Kullanıcı’nın herhangi bir sebepten dolayı Ürün’ü teslim
              alamaması halinde Manjo, hiçbir sorumluluk kabul etmez ve
              hizmetini tam ve eksiksiz olarak yerine getirmiş kabul edilir. Bu
              nedenle, Kullanıcı’nın Ürün’ü geç teslim almasından kaynaklanan
              her türlü zarar ile Ürün’ün Kullanıcı’nın kusurundan dolayı
              beklemiş olması ve/veya Ürün’ün teslim edilememesi nedeniyle
              Manjo’ya iade edilmesinden dolayı oluşan giderler de Kullanıcı’ya
              aittir. Manjo, bu durumlarda Kullanıcı’ya hizmet vermeyi
              durdurabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              f) Manjo’nun sunduğu hizmetten yararlanan Kullanıcı, Uygulama’yı
              hukuka ve amacına uygun şekilde kullanmayı ve Uygulama dahilinde
              yaptığı her türlü işlemden bizzat kendisinin sorumlu olduğunu
              kabul eder. Kullanıcı’nın Uygulama aracılığıyla ve/veya dahilinde,
              Koşullara ve hukuka aykırı olarak gerçekleştirdiği herhangi bir
              işlem, eylem ve/veya faaliyetleri nedeniyle Manjo doğrudan veya
              dolaylı olarak hiçbir şekilde sorumlu tutulamaz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              g) Kullanıcı, Uygulama’ya yetkisiz şekilde ulaşmamayı ve yazılımı
              hiçbir şekilde değiştirmemeyi, değiştirilmiş olduğu belli olanları
              kullanmamayı, diğer Kullanıcıların bilgisayarındaki bilgilere veya
              yazılıma zarar verecek bilgi veya programlar göndermemeyi ve
              anılan kurallara uymadığı durumlarda Manjo’nun uğrayabileceği tüm
              maddi ve manevi zararı karşılamayı kabul ve taahhüt eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              h) Kullanıcı, Uygulama’da yer alan hizmetleri kötüye kullanmamayı,
              Uygulama’yı kullanan diğer Kullanıcıların haklarını ihlal eden
              veya zarar veren hiçbir davranışta bulunmamayı, diğer
              Kullanıcıların Uygulama deneyimini bozacak veya kötü etkileyecek
              herhangi bir davranışta bulunmamayı, diğer Kullanıcıları taciz ve
              tehdit etmemeyi, kişi veya kurumların isimlerini lekeleyici,
              çiğneyici, ahlaka veya kanuna aykırı materyal veya bilgiler
              yayımlamamayı, reklam satış veya promosyon yapmamayı, Türkiye
              Cumhuriyeti kanunlarına veya uluslararası anlaşmalara aykırı,
              politik mesajlar içeren mesaj veya yorum göndermemeyi, Uygulama’ya
              eklenecek bilgilerin, mesaj veya yazışmaların genel ahlak, görgü
              ve hukuk kurallarına aykırı olmamasını, aksi takdirde Manjo’nun
              Kullanıcı’nın hesabını limitleme veya kapatma hakkının
              bulunduğunu, verdiği herhangi bir zararın Kullanıcı tarafından
              karşılanacağını kabul, beyan ve taahhüt eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              i) Kullanıcı, Tütün Mamulleri ve Alkollü İçkilerin Satışına ve
              Sunumuna İlişkin Usul ve Esaslar Hakkında Yönetmelik ve ilgili
              mevzuat uyarınca Uygulama üzerinden kesinlikle tütün mamulleri ve
              alkollü içecek satışı yapılmadığını, tütün mamulleri ve alkollü
              içecek temin etmemeyi ve Üye İşyeri’ne bu konuda talepte
              bulunmamayı, aksi takdirde üyeliğinin iptali ve Koşulların feshi
              ile sonuçlanabileceğini kabul, beyan ve taahhüt eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              j) Kullanıcı, Manjo’nun Uygulama’da çerez ve diğer tanıma
              teknolojilerini kullanabileceğini, dilediği zaman veya sürekli
              olarak tüm sistemi izleyebileceğini kabul, beyan ve taahhüt eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              k) Kullanıcı, kendi kullandığı cihazındaki arızalar, bilgi kaybı
              ve diğer kayıpların sorumluluğunun tamamen kendisine ait olduğunu,
              Uygulama’nın kullanımından dolayı uğrayabileceği zararlardan
              dolayı Manjo'dan tazminat talep etmemeyi kabul eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              l) Kullanıcı, Uygulama üzerinde verilen tüm siparişlerin, yapılan
              alışverişlerin kişisel kullanım ve tüketim amaçlı olarak son
              kullanıcıya yapıldığını, tekrar satış amaçlı olmadığını kabul
              eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              m) Kullanıcı, Ürün siparişi ve Manjo’nun sunduğu hizmetlere
              ilişkin tüm şikayetlerini Manjo’nun müşteri hizmetlerine
              info@manjo.app e-posta adresi üzerinden aktarabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5.2. Manjo’nun Hak ve Yükümlülükleri:
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              a) Manjo sadece bir aracı olarak hareket eder ve bu nedenle Üye
              İşyeri tarafından sunulan ürün/hizmetlerin kalitesinden, doğru
              sağlanmasından ya da Üye İşyeri’nin Kullanıcı’ya Uygulama
              üzerinden sunduğu ürün/hizmetler nedeniyle vermiş olduğu zararın
              tazmininden sorumlu değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              b) Manjo üzerinden verilen yemek siparişlerinde, teslimat Üye
              İşyeri menülerinin sipariş anındaki mevcudiyetiyle sınırlıdır.
              Manjo, ilgili Üye İşyeri’nde bulunmayan ürünlerin Kullanıcı’ya
              teslimini sağlayamayabilir. Ürünlerin Uygulamada teşhir edilmesi
              bunların Üye İşyeri’nin stoğunda bulunduğu anlamına gelmeyebilir.
              Ürünlerin Kullanıcı’ya teslim edilememesinde Manjo sorumlu
              değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              c) Uygulama üzerinden satışa sunulan Üye İşyeri’ne ait
              ürün/hizmetlerin fiyat veya ürün özelliklerinde hata olması
              durumunda Manjo, Kullanıcı’yı bilgilendirerek, bu hatayı
              düzeltecek şekilde teslimatı yapabilir veya siparişi iptal
              edebilir. Uygulama üzerinden Üye İşyeri’nin satışa sunduğu
              ürünlerin içeriğine dair doğru ve dürüst açıklama yapılmasına dair
              Manjo gerekli özeni gösterecektir ancak Üye İşyeri’nin sipariş
              içeriklerine dair yaptıkları eksik ve/veya hatalı açıklamalardan
              dolayı oluşacak problemlerden veya zararlardan ve Üye İşyerlerinin
              sağladığı içerikten Manjo sorumlu değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              d) Manjo, Uygulama içinde zamanla ilave hizmetler açabilir, bazı
              hizmetlerini kısmen veya tamamen değiştirebilir veya ücretli hale
              dönüştürebilir. Bu durumda kullanıcının Koşulları feshederek,
              üyelikten ayrılma hakkı saklıdır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              e) Manjo herhangi bir zamanda Uygulama’nın çalışmasını geçici bir
              süre askıya alabilir veya tamamen durdurabilir. Uygulama’nın veya
              Online Ödeme Yöntemi kullanımının geçici bir süre askıya alınması
              veya tamamen durdurulmasından dolayı Manjo’nun Kullanıcılarına
              veya üçüncü kişilere karşı hiçbir sorumluluğu yoktur. Manjo,
              güvenlik şüphesi doğuran Kullanıcı işlemlerinden dolayı ilgili
              Kullanıcıların Online Ödeme Yöntemi aracılığı ile ödeme yapma
              imkânını geçici bir süre askıya alabilir veya tamamen
              durdurabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              f) Manjo, Kullanıcıların Koşullara veya kurallara aykırı
              davrandığını fark ettiği takdirde gerekli müdahalelerde bulunma ve
              Kullanıcı’yı hizmet dışına çıkarma ve üyeliğine son verme hakkına
              sahiptir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              g) Kullanıcıların Uygulama’yı kullanırken ileri sürdüğü şahsi
              fikir, düşünce, ifade, Manjo ortamına eklediği dosyalar,
              gönderdiği kişisel bilgiler ve Üye İşyeri ile Kullanıcı arasında
              oluşabilecek ihtilaflar dahil ancak bunlarla sınırlı olmamak üzere
              Manjo bu dosyalardan dolayı hiçbir şekilde sorumlu değildir. Manjo
              bu görüş ve düşünceleri yayımlayıp, yayımlamamakta serbesttir,
              ayrıca bu görüş ve yorumları moderatörler aracılığıyla düzenleme
              ve imla hatalarını düzeltme hakkına sahiptir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              h) Manjo, Kullanıcı’nın Uygulama dışındaki web-sitelerine geçişini
              sağlayabilir. Bu takdirde Kullanıcı geçiş yapacağı sitelerin
              içeriğinden Manjo'nun sorumlu olmadığını peşinen kabul eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              i) Kullanıcı, gerekli Ürünler hakkında sipariş öncesi teslimat,
              ödeme, iade ve cayma hakkı gibi konularda Ön Bilgilendirme Formu
              ve Mesafeli Satış Sözleşmesi’nde detaylı olarak
              bilgilendirilecektir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              j) Manjo, hizmetlerinin zamanında, güvenli ve hatasız olarak
              sunulması, hizmet kullanımından elde edilen sonuçların doğru ve
              güvenilir olması, hizmet kalitesinin beklentilere cevap vermesi
              için gerekli özeni gösterecektir ancak bunları taahhüt
              etmemektedir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6. Sorumluluk Sınırlamaları
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.1. Manjo, kendi belirlediği ve Uygulama üzerinden sipariş
              verilebilen kapsama alanlarında teslimat yapmaktadır, Uygulama
              kapsamı içinde olmayan bölgelerden sorumlu değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.2. Kullanıcı’ya Uygulama üzerinden gösterilen teslimat süresi,
              Manjo’nun teslimatın gerçekleşmesini hedeflediği tahmini ve
              bağlayıcı olmayan bir süredir. Herhangi bir sebepten Ürün’ün
              hedeflenen süre içerisinde teslim edilememesinden Manjo sorumlu
              değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.3. Bahşiş ödemeleri Kullanıcı memnuniyetine bağlıdır ve
              tamamıyla Kullanıcı’nın isteği üzerine uygulanır. Manjo,
              Kullanıcı’nın yaptığı bahşiş ödemeleri konusunda herhangi bir
              sorumluluk kabul etmez ve iade işlemi gerçekleştirmez.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.4. Manjo, Kullanıcı’dan kaynaklanan teknik aksaklıklar sebebiyle
              hizmetten faydalanılamaması durumunda yükümlülüklerini
              gerçekleştirememesi nedeniyle sorumlu tutulamayacak, veri
              kaybından sorumlu olmayacak ve kendisinden herhangi bir şekilde
              herhangi bir talepte bulunulmayacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.5. Ürün siparişine ilişkin fiş veya fatura Üye İşyeri tarafından
              hazırlanarak sipariş ile birlikte Kullanıcı’ya teslim edilir.
              Manjo’nun fiş veya fatura teslimatı gibi herhangi bir yükümlülüğü
              yoktur.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.6. Manjo, Uygulama’nın kullanımıyla ilgili olarak, herhangi bir
              üçüncü şahsın, Kullanıcıların, Üye İşyerlerinin reklam verenlerin
              ve/veya sponsorların fiil, ihmal ve davranışlarına ilişkin sorumlu
              değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.7. Manjo, Kullanıcı verilerinin yetkisiz kişilerce okunmasından
              veya kullanılmasından, Kullanıcı paylaşımlarının üçüncü kişiler
              tarafından izinsiz şekilde kullanılmasından, paylaşılmasından veya
              yayınlanmasından dolayı gelebilecek zararlardan Manjo sorumlu
              değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.8. Manjo, Kullanıcıların Uygulama’yı kullanmaları sırasında
              ortamda bulunduracakları dosyaların, mesajların bazılarını veya
              tamamını uygun göreceği periyotlarla yedekleme ve silme yetkisine
              sahiptir. Yedekleme ve silme işlemlerinden dolayı Manjo sorumlu
              tutulmayacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6.9. Manjo koruma amaçlı makul tedbirleri almaktadır. Ancak
              kendisine ait bilgisayar ağına ve bu ağdaki mevcut veri tabanı
              bilgilerine yapılabilecek saldırılar sonucu Kullanıcı bilgilerinin
              kötü amaçlı kişilerin eline geçmesi ve bunların kötü niyetli
              kullanılması halinde doğabilecek sonuçlardan dolayı sorumluluk
              kabul etmez.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7. Ödeme Koşulları
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.1. Manjo kredi kartlarının ilk altı hanesini sistemlerinde
              muhafaza etmektedir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.2. Online Ödeme Yöntemi kullanılarak verilen siparişlerde,
              Manjo, sipariş edilen Ürünlere ilişkin bedelleri Üye İşyerlerinin
              nam ve hesabına Kullanıcılardan tahsil etmek için Üye İşyerleri
              tarafından münhasır olarak yetkilendirilmiştir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.3. Ürün satış fiyatı, hizmet ücreti, teslimat masrafları, taşıma
              araçlarına ilişkin bedeller ve diğer her türlü vergi, resim, harç
              gibi ek ücretler, Kullanıcı’nın seçtiği kredi kartından veya diğer
              ödeme yöntemlerinden, sipariş verildiğinde tahsil edilecektir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.4. Kullanıcı, Online Ödeme Yöntemi veya Üye İşyerlerinin
              Uygulama’da sunduğu diğer seçenekler ile fakat bunlarla sınırlı
              olmamak üzere her türlü ödeme yöntemiyle ödemesini
              gerçekleştirebilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.5. Kullanıcı, Manjo’ya ödemeyi yaptığı anda ödeme
              yükümlülüklerini ifa etmiş olmaktadır. Ürün değişikliği olmadığı
              sürece, kendisinden sipariş teslimi esnasında veya sonrasında,
              bahşiş ödemesi haricinde herhangi bir ücret talep edilmez.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.6. Online Ödeme Yöntemi kapsamında kullanılan kredi kartının,
              kart hamili haricinde, bir başkası tarafından hukuka aykırı
              şekilde kullanılması halinde 23.02.2006 tarihli 5464 sayılı Banka
              Kartları ve Kredi Kartları Kanunu ve 10.03.2007 tarihli ve 26458
              sayılı Resmi Gazete’de yayımlanan Banka Kartları ve Kredi Kartları
              Hakkında Yönetmelik hükümlerine göre işlem yapılır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              8. Ücret İadesi
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              8.1. Online Ödeme Yöntemi ile ödemesi yapılmış siparişlerle
              sınırlı olmak üzere Kullanıcı’ya ücret iadesi ancak
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Siparişin ilgili Üye İşyeri’ne iletilememesi veya Üye İşyeri’nin
              aşırı yoğunluk, elektrik arızası, kaza gibi haller nedeniyle
              siparişi hazırlayabilecek ya da teslim edebilecek durumda olmaması
              nedeniyle siparişin zorunlu iptali;
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Üye İşyeri tarafından siparişin hazırlanmadığı veya siparişin
              hazırlanmış olmasına rağmen Üye İşyeri’nin iptal işlemini
              onaylamış olduğu hallerde Kullanıcı talebi üzerine siparişin
              iptali;
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Siparişin bir kısmının veya tamamının Üye İşyeri’nde mevcut
              olmaması nedeniyle siparişin kısmen veya tamamen zorunlu iptali;
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Sipariş edilen Ürün’den memnun kalınmaması nedeniyle ilgili Üye
              İşyeri’nin onayının alınmasının akabinde yapılan sipariş iptali;
              ve
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Sipariş edilen Ürün’ün teslimatının gecikmesi durumunda ilgili
              Üye İşyeri’nin onayı üzerine yapılan iptal durumunda yapılacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              8.2. Kullanıcı’ya yapılacak ücret iadelerinde, iptale konu
              siparişe ilişkin ilgili Üye İşyeri bir fiş veya fatura düzenlemiş
              ve bu Kullanıcı’ya teslim edilmiş ise, söz konusu fiş ya da
              faturanın Üye İşyeri görevlisi veya Manjo’ya iadesi gerekmektedir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              8.3. Online Ödeme Yöntemi dışındaki ödeme vasıtaları ile ödemesi
              yapılmış siparişlerde ücret iadesi, Manjo’nun bünyesinde
              bulundurduğu Üye İşyerleri tarafından doğrudan yapılmaktadır.
              Manjo, Üye İşyerleri tarafından yapılacak Ürün iadesi koşullarına
              ilişkin olarak doğabilecek anlaşmazlıklarda hiçbir sorumluluk
              kabul etmemektedir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              8.4. Manjo, yalnızca kullanıcıdan kaynaklanan sebeplerden dolayı
              (örneğin kullanıcının siparişi teslim almaması veya almaya çok geç
              gelmesi gibi) ücret iadesi yapmama hakkını saklı tutar.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              9. Kişisel Verilerin Korunması
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              9.1. Manjo, kişisel verilerin korunmasına yüksek derecede önem
              vermektedir. Kullanıcı, Kişisel verilerine ilişkin düzenlemelere
              https://manjo.app internet sitesinden erişebilir veya
              info@manjo.app e-posta adresi üzerinden detaylı bilgi alabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              9.2. Kullanıcı, Uygulama’da oluşturulmuş her bir Kullanıcı için
              yalnızca bir adet telefon numarası tanımlanabileceğini, tanımlanan
              telefon numarasının değiştirilemeyeceğini, yeni bir telefon
              numarasının ancak Uygulama üzerinde yeni bir kullanıcı
              oluşturulması halinde tanımlatılabileceğini, kayıtlı telefon
              numarasıyla yapacağı her türlü işlemden bizzat kendisinin sorumlu
              olduğunu; kabul, beyan ve taahhüt eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              9.3. Kullanıcı, Manjo’ya üye olurken Koşulları onaylamak ve üyelik
              sonrası Uygulama içerisindeki hareketleri esnasında, veri
              sorumlusu Manjo ile paylaştıkları ad, soyad, elektronik posta
              adresi, telefon numarası gibi kişisel verilerinin (“Kişisel
              Veri/ler”) ve buna ek olarak Kullanıcı Kişisel Verileri dışındaki
              sipariş bilgilerinin (sipariş verdikleri Üye İşyeri, sipariş
              verilen Ürün tipi, sipariş adedi, sipariş saati, sipariş verilen
              bölge gibi bilgiler) Uygulama üzerindeki tüm davranışları ve
              verilerinin (“Veri/ler”) Kişisel Verilerin Korunması Hakkındaki
              Kanun’da belirlenmiş olan esaslar çerçevesinde işlenmesine, Manjo,
              Manjo iştirakleri ve iş ortakları tarafından pazarlama, analiz,
              istatistik gibi amaçlarla işlenmesine, anonimleştirilerek
              kullanılmasına, 3. kişilere ve yurtdışına aktarılmasına açıkça
              rıza gösterdiğini kabul ve beyan eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              10. Fikri Mülkiyet Hakları
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              10.1. Manjo'nun; Manjo hizmetleri, fikri mülkiyet hakları, Manjo
              ticari markaları, Manjo ticari görünümü veya Uygulama’nın tasarım,
              metin, imge, html kodu ve diğer kodlar da dahil fakat bunlarla
              sınırlı olmamak kaydıyla, tüm unsurları, her tür ayni ve şahsi
              hakları, ticari bilgi ve know-how'a yönelik tüm hakları münhasıran
              Manjo’ya aittir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              10.2. Kullanıcı, Manjo hizmetlerini ve Manjo'nun yukarıda sayılan
              çalışmalarını ticari amaçla kullanamaz, çoğaltamaz, dağıtamaz veya
              bunlardan türetilmiş çalışmalar yapamaz veya hazırlayamaz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              10.3. Kullanıcı, Uygulama dahilinde bulunan, Manjo ve/veya başka
              bir üçüncü kişilere ait resimleri, metinleri, görsel ve işitsel
              imgeleri, video kliplerini, dosyaları, veri tabanlarını,
              katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını,
              dağıtmayacağını ve işlemeyeceğini kabul eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              10.4. Manjo, Uygulama’ya eklenecek fotoğraflar ve yazışmalar fakat
              bunlarla sınırlı olmamak üzere her türlü içeriği yayımlama, işleme
              ve çoğaltma, yayma, temsil, işaret, ses ve/veya görüntü nakline
              yarayan araçlarla umuma iletim, üçüncü kişilere devir ve temlik
              hakları dâhil olmak üzere 5846 sayılı ve 5.12.1951 tarihli Fikir
              ve Sanat Eserleri Kanunu’nda sayılan tüm mali haklarına sahiptir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              10.5. Manjo, Kullanıcılarının ürettiği ve yayınlanmak üzere kendi
              iradesiyle Uygulama’ya yüklediği bilgi, belge, yazılım, tasarım,
              grafik vb. eserlerin her türlü yazılı ve görsel mecrada
              yayınlanma, işleme, sosyal medya ağlarında paylaşma ve/veya Manjo
              tarafından uygun görülecek başka bir adrese taşıma veya kendi
              takdirinde olmak üzere Uygulama’dan kaldırma haklarına sahiptir.
              Yayınlanan bu bilgilerin başka Kullanıcılar veya Manjo tarafından
              kullanması halinde Kullanıcı, Manjo'dan hiçbir telif ücreti talep
              etmeyecektir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              11. Delil Sözleşmesi
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Taraflar, Koşullardan doğabilecek her türlü ihtilafta Manjo’nun
              resmi defter ve ticari kayıtlarıyla, veri tabanında ve
              sunucularında tuttuğu elektronik bilgilerin, bilgisayar ve ses
              kayıtlarının, Manjo sisteminde kayıtlı kullanıcı bilgileri,
              siparişleri, yorum/değerlendirmeleri vb. bilgilerin, delil teşkil
              edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu’nun 193. maddesi
              anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              12. Sözleşme’nin Yürürlüğü ve Feshi
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              12.1. Koşullar, Kullanıcı’nın Uygulama’yı açması ve/veya
              kullanması ve kullanıcı kayıt formunu doldurmasından itibaren
              süresiz olarak yürürlüğe girer.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              12.2. Koşullar, Uygulama kullanılmaya devam edildiği ve
              Kullanıcı’ya Manjo tarafından yeni bir sözleşme sunulmadığı sürece
              yürürlükte kalacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              12.3. Taraflar işbu Sözleşme’yi diledikleri zaman sona
              erdirebilirler.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              12.4. Koşulların feshi anında tarafların birbirlerinden olan
              alacak hakları etkilenmez.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              13. Muhtelif Hükümler
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              13.1. Koşulların herhangi bir hükmünün veya Koşullarda yer alan
              herhangi bir ifadenin geçersizliği, yasaya aykırılığı ve
              uygulanamazlığı, Koşulların geri kalan hükümlerinin yürürlüğünü ve
              geçerliliğini etkilemeyecektir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              13.2. İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir.
              Taraflar arasındaki her türlü uyuşmazlık ve/veya anlaşmazlıkta
              İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili
              olacaktır.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
