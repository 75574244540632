/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "Manjo nedir?",
    answer:
      "Manjo, restoranlar ile müşterilerini bir araya getiren sosyal bir yemek platformudur. Manjo restoranlara dijital sipariş çözümleri sunarak müşterilerine daha hızlı ve verimli bir şekilde hizmet edebilmelerine yardımcı olur. Müşteriler ise Manjo'yu en sevdikleri restoran ve kafelerden hiç beklemeden sipariş vermek için, arkadaşlarına restorandan puan ve hediyeler göndermek için kullanır.",
  },
  {
    question: "Nasıl kullanırım?",
    answer:
      "Manjo restoranda sıraya girmeden, hatta restorana gelmeden siparişini verebilmeni ve ödeme yapabilmeni sağlar. Uygulama size tahmini bir bekleme süresi verir ve siparişiniz hazır olunca size bildirim gönderir. Restoranda isminizi söyleyip siparişinizinizi teslim alabilirsiniz.",
  },
  {
    question: "Siparişimde eksik veya hatalı ürünler var 😟",
    answer:
      "Eğer siparişinizde eksik veya hatalı ürünler varsa restorana durumu söyleyin. Size siparişin düzeltilmesi veya iade konusunda yardımcı olacaklardır. Restoran size yardımcı olmazsa bize info@manjo.app adresi üzerinden ulaşabilirsiniz.",
  },
  {
    question: "Restoran siparişimi onaylamıyor.",
    answer:
      "Restoranların siparişleri onaylaması ve hazırlamaya başlaması ortalama 4 dakika sürmektedir. Bu süreyi kısa tutmak için ikinci dakikada Manjo, restoran ile iletişime geçer. Restoran siparişinizi hazırlamaya başladığında ve siparişiniz hazır olduğunda size bildirim göndereceğiz.",
  },
  {
    question: "Siparişimi nasıl iptal ederim?",
    answer:
      "Siparişinizin ilerleme çubuğunun altındaki 'İptal' düğmesine dokunun. Ne yazık ki, restoran siparişinizi hazırlamaya başladıysa, sipariş uygulamadan iptal edilemez ve değiştirilemez. Ancak restoranı arayıp siparişinizi değiştirmelerini veya iptal etmelerini isteyebilirsiniz. İptal konusunda restoran ile anlaşırsanız, geri ödeme restoranın onayıyla gerçekleştirilir.",
  },
  {
    question: "Telefon numaram ile kayıt olamıyorum.",
    answer:
      "Yaşadığınız sorun için özür dileriz. Telefon numaranıza kayıtlı bir Manjo hesabı olabilir. Eğer giriş yapmakta problem yaşıyorsanız info@manjo.app mail adresimizden bize ulaşabilirsiniz.",
  },
  {
    question: "Manjo hesabım var ama telefon numaramı değiştirdim.",
    answer:
      "Bizimle info@manjo.app üzerinden iletişime geçebilirsin. Hesabını yeni telefon numarana geçirmek konusunda yardımcı olacağız.",
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CustomerFAQ() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
