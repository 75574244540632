/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/

import Header from "./components/header";
import Footer from "./components/footer";
import { Link } from "react-router-dom";
const tabs = [
  { name: "Kullanım Koşulları", href: "/kullanim-kosullari", current: false },
  {
    name: "Kişisel Verilerin Korunması Politikası",
    href: "/kvkk",
    current: false,
  },
  { name: "Aydınlatma Metni", href: "/aydinlatma-metni", current: false },
  { name: "Gizlilik Politikası", href: "/gizlilik", current: true },
  { name: "Yardım", href: "mailto:info@manjo.app", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Privacy() {
  return (
    <div>
      <Header></Header>
      <div className="m-12">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? "bg-manjOrange-lightest text-manjOrange"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-manjOrange font-semibold tracking-wide uppercase">
                Manjo
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                GİZLİLİK POLİTİKASI
              </span>
            </h1>

            <p className="mt-8 text-xl text-gray-500 leading-8">
              Manjo Elektronik İletişim A.Ş. (“Manjo”) olarak, Manjo
              kullanıcılarının (“Kullanıcı”) bilgilerinin korunmasına ve
              gizliliğine önem veriyoruz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              İşbu Gizlilik Politikası (“Politika”), Manjo’nun mobil uygulamalar
              aracılığıyla topladığı bilgileri nasıl kullandığı, kullanıcıların
              sorumlulukları ve Manjo’nun gizli bilgilerin korunmasına ilişkin
              kuralları hakkında kullanıcıları bilgilendirmeyi amaçlamaktadır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              İşbu Gizlilik Politikası ile kişisel verilerinizin işlenmesi
              hakkında aydınlatılmış olduğunuzu ve kişisel verilerinizin burada
              belirtilen şekilde kullanılmasına onay verdiğinizi kabul
              etmektesiniz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              1. Topladığımız Bilgiler
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              1.1. Manjo mobil uygulamasını kullanmanız durumunda, üyelik
              kaydınızı gerçekleştirmek, talep ettiğiniz ürün/hizmetleri size
              temin etmek ve ödemeleri gerçekleştirmek gibi sair süreçler için
              belirli bilgileriniz toplanmaktadır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              1.2. Manjo’nun işlediği kişisel verileriniz aşağıda belirtilen
              şekilde Kişisel Verilerin Korunması Kanunu’na (Kanun) uygun olarak
              kategorize edilmiştir. Aksi açıkça belirtilmedikçe, Politika
              kapsamında arz edilen hüküm ve koşullar kapsamında “kişisel veri”
              ifadesi aşağıda yer alan bilgileri kapsayacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Kimlik ve İletişim Bilgisi: ad, soyad, telefon, adres, işyeri
              bilgisi, e-posta adresi Kullanıcı Bilgisi, Kullanıcı İşlem Bilgisi
              ve Finansal Bilgi: üyelik bilgileri, üyelik ID numarası, Manjo
              hizmetlerinden yararlandığınız tarih ve saate ilişkin veriler,
              Manjo ile iletişime geçme nedenleriniz, uygulama üzerinde arama
              yaparken kullandığınız terimler ve filtreleme tercihleriniz, puan
              ve yorumlarınız, yemek tercihleriniz, ziyaret ettiğiniz restoran
              kategorileri, kullanım sırasında oluşan hatalar, fatura ve ödeme
              bilgisi Lokasyon Bilgisi: Manjo’nun hizmetlerini kullanmanız
              esnasında elde etmiş olduğumuz, GPS verisi gibi konumunuza ilişkin
              belirli veya yaklaşık konum bilgileri İşlem Güvenliği Bilgisi: log
              in credential bilgileri, cihaz bilgileri, IP adresi Pazarlama
              Bilgisi: alışkanlıklarınız ile beğenilerinizi gösteren raporlar ve
              değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları
              vb. bilgiler Talep/Şikâyet Yönetimi Bilgisi: Uygulama üzerinden
              yaptığınız talep ve şikayetler ile Uygulama’da paylaştığınız
              yorumlarınız 1.3. Kanunun 3. ve 7. maddeleri çerçeve anonim hale
              getirilen veriler, anılan kanun hükümleri uyarınca kişisel veri
              olarak kabul edilmeyecek ve bu verilere ilişkin işleme
              faaliyetleri işbu Gizlilik Politikası hükümleri ile bağlı
              olmaksızın gerçekleştirecektir. Verilerinizin işlenip işlenmediği
              konusunda başvuru konusunda bkz. Manjo Kişisel Verilerin İşlenmesi
              ve Korunması Politikası (“KVK Politikası”).
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              2. Bilgileri Kullanma Amaçlarımız
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              2.1. Manjo, mobil uygulama üzerinden topladığı bilgileri başlıca
              aşağıda belirtilen amaçlarla kullanılmaktadır:
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Mobil uygulama aracılığıyla kullanıcılara hizmet sunmak,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Kullanıcılar ile iletişime geçmek, kullanıcıların talep ve
              şikayetlerini sonuçlandırmak,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Ödemelere ilişkin finans ve muhasebe süreçlerini yürütmek,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Hizmetlerimizin tanıtımını sağlamak; reklam ve kampanya
              süreçlerini yürütmek,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Kullanıcı geri bildirimlerine göre ürün ve hizmetlerimizi
              iyileştirmek/geliştirmek,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Hizmetlerimizi kullanıcıların tercih, kullanım alışkanlıkları ve
              ilgi alanlarına göre özelleştirmek,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Mobil uygulamadaki sistemsel sorunları tanımlamak ve gidermek;
              bilgi güvenliği süreçlerini yürütmek,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              · Kullanıcı deneyimlerini ölçümlemek, mobil uygulama performansını
              geliştirmek.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              2.2. Bu kısımda belirtilen amaçlar, mobil uygulama üzerinden
              toplanan bilgilerin genel kullanılma amaçlarını ifade etmektedir.
              Manjo’nun müşteri kişisel verilerini işleme amaçları ve kişisel
              verilerin işlenmesi hakkında diğer konularda bilgi almak için
              lütfen KVK Politikası’nı inceleyiniz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3. Bilgileriniz Üçüncü Kişilere Aktarılması
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3.1. Kullanıcı’nın veri sorumlusu Manjo ile paylaştığı kişisel
              veriler, yukarıda belirtilen amaçların gerçekleştirilebilmesi
              için, Kanun’da belirlenmiş olan esaslar çerçevesinde işlenebilir,
              Manjo, Manjo iştirakleri ve iş ortakları tarafından pazarlama,
              analiz, istatistik gibi amaçlarla işlenebilir, anonimleştirilerek
              kullanılabilir, 3. kişilere ve yurtdışına aktarılabilir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3.2. Bilgileriniz, iş ortaklarımız ve ürün/hizmet tedariki
              kapsamında destek alınan tedarikçiler dışında yasal
              yükümlülüklerimizi yerine getirebilmek için, bilgilerinizi
              paylaşmamız talep edildiğinde, yetkili kişi, kurum ve kuruluşlar
              ile de paylaşılabilecektir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3.3. Kullanıcıların sağladığı bilgiler, kullanıcılara sunulan
              metinlerde belirtilen amaçlar ve hizmetin sunulabilmesi için
              gereken durumlar haricinde üçüncü kişilere aktarılmayacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4. Bilgileriniz Saklanması ve Korunması
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4.1. Bilgileriniz, bilgileri toplama amacımızın gerektirdiği süre
              kadar saklanmaktadır. Mevzuatta bilgilerinizi saklamamız için özel
              bir süre öngörülüyor ise, bu süreye uygunluk sağlanmaktadır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4.2. Manjo, bilgilerinizin muhafaza edildiği sistemleri,
              sunucuları ve veri tabanlarını, veri güvenliği tehditlerine (siber
              saldırı, hash vb.) karşı korumak için gerekli teknik ve idari
              tedbirleri almaktadır. Ancak sistemsel sorunlar veya dışarıdan
              saldırılar sonucu yaşanabilecek aksaklıklardan veya zararlardan
              sorumlu değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4.3. Manjo kredi kartının ilk altı hanesini sistemlerinde muhafaza
              etmektedir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4.4. Kullanıcı, Manjo tarafından mobil uygulama aracılığıyla
              sunulan hizmetlerden yararlanabilmek amacıyla kullandığı sistem
              erişim araçlarının (kullanıcı ismi, şifre vb.) güvenliği,
              saklanması, üçüncü kişilerin bilgisinden uzak tutulması ile ilgili
              tüm sorumluluğun tamamen kendisine ait olduğunu kabul etmektedir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5. Bilgileriniz Doğruluğu ve Güncelliği
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5.1. Kullanıcıların sağladığı bilgilerin doğruluğu ve güncelliği
              kullanıcıların sorumluluğundadır. Bilgilerinizin gerçeğe uygun
              olarak tarafımıza iletilmesi ve bilgilerinizde bir değişiklik
              olması durumunda güncellenmesi gerekmektedir. Manjo ile paylaşmış
              olduğunuz bilgileri, mobil uygulama üzerinden veya Manjo müşteri
              hizmetlerine ulaşarak dileğiniz zaman güncelleyebilirsiniz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5.2. Güncel bilgileri sağlamamış olmanız halinde Manjo’nun
              herhangi bir sorumluluğu olmayacaktır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5.3. Manjo’ya bir başkası adına bilgi sağladığınızda, bu
              bilgilerin doğru olduğunu, bilgileri Manjo ile paylaşma hakkına
              sahip olduğunuzu/bu konuda yetkilendirildiğinizi taahhüt etmiş
              sayılırsınız.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              6. Üçüncü Taraflara Yapılan Yönlendirmeler
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Manjo mobil uygulaması, Manjo tarafından işletilmeyen üçüncü taraf
              bağlantılara yönlendirmeler yapabilir. İlgili bağlantılardan
              herhangi birini ziyaretiniz sırasında, ziyaret ettiğiniz üçüncü
              tarafın gizlilik politikaları ve kullanım koşullarına tabi
              olursunuz. Manjo söz konusu üçüncü tarafların politika ve
              uygulamalarından sorumlu değildir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7. Mobil Uygulamada ve Bu Politikada Yapılan Değişiklikler
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.1. Manjo, mobil uygulama aracılığıyla sunulan hizmetleri,
              bilgileri, görselleri ve sair unsurları önceden bildirimde
              bulunmaksızın değiştirme, kullanımını askıya alma veya durdurma
              hakkını saklı tutmaktadır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              7.2. Manjo, bu Politika üzerinde değişiklik ve güncelleme yapma
              hakkını saklı tutmaktadır. Politika’nın güncel versiyonunu takip
              etmek, kullanıcının sorumluluğundadır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              8. Sorumluluk
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Mobil uygulamanın kullanımı sırasında oluşabilecek herhangi bir
              hata, kesinti, gecikme, virüs, hat ve/veya sistem arızası
              sonucunda ortaya çıkabilecek doğrudan veya dolaylı zarar ve
              masraflardan Manjo sorumlu değildir.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
