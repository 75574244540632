/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/

import Header from "./components/header";
import Footer from "./components/footer";
import { Link } from "react-router-dom";
const tabs = [
  { name: "Kullanım Koşulları", href: "/kullanim-kosullari", current: false },
  {
    name: "Kişisel Verilerin Korunması Politikası",
    href: "/kvkk",
    current: false,
  },
  { name: "Aydınlatma Metni", href: "/aydinlatma-metni", current: true },
  { name: "Gizlilik Politikası", href: "/gizlilik", current: false },
  { name: "Yardım", href: "mailto:info@manjo.app", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Clarification() {
  return (
    <div>
      <Header></Header>
      <div className="m-12">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? "bg-manjOrange-lightest text-manjOrange"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-manjOrange font-semibold tracking-wide uppercase">
                Manjo
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                KİŞİSEL VERİLERİN KORUNMASI KANUNU UYARINCA AYDINLATMA METNİ
              </span>
            </h1>

            <p className="mt-8 text-xl text-gray-500 leading-8">
              İşbu Kişisel Verilerin Korunması Kanunu Uyarınca Aydınlatma Metni
              (“Aydınlatma Metni”) veri sorumlusu sıfatıyla hareket eden Manjo
              Elektronik İletişim A.Ş. (“Manjo”) tarafından Manjo’nun 6698
              sayılı Kişisel Verilerin Korunması Kanunu’na (“Kanun”) uygun
              olarak Manjo kullanıcılarının (“Kullanıcı”) kişisel verilerinin
              işlenmesine ilişkin açıklamalarda bulunmak ve bilgilendirmek
              amacıyla kaleme almıştır. Manjo ile paylaştığınız kişisel
              verilerinizin işlenmesine ilişkin daha detaylı bilgiye web
              sitemizde yer alan Manjo Kişisel Verilerin Korunması (“KVK”) ve
              Gizlilik Politikası’ndan erişebilirsiniz. Kişisel verilerinizin
              korunması ve işlenmesine yönelik her türlü sorunuz için
              info@manjo.app e-posta adresi üzerinden bizimle iletişime
              geçebilirsiniz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              1. Toplanan Kişisel Veriler
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Manjo ile olan hukuki ilişkiniz kapsamında paylaştığınız
              verileriniz aşağıdaki şekilde sınıflandırılmıştır: Kimlik ve
              İletişim Bilgisi: ad, soyad, telefon, adres, işyeri bilgisi,
              e-posta adresi Kullanıcı Bilgisi, Kullanıcı İşlem Bilgisi ve
              Finansal Bilgi: üyelik bilgileri, üyelik ID numarası, Manjo
              hizmetlerinden yararlandığınız tarih ve saate ilişkin veriler,
              Manjo ile iletişime geçme nedenleriniz, uygulama üzerinde arama
              yaparken kullandığınız terimler ve filtreleme tercihleriniz, puan
              ve yorumlarınız, yemek tercihleriniz, ziyaret ettiğiniz restoran
              kategorileri, kullanım sırasında oluşan hatalar, fatura ve ödeme
              bilgisi Lokasyon Bilgisi: Manjo’nun hizmetlerini kullanmanız
              esnasında elde etmiş olduğumuz, GPS verisi gibi konumunuza ilişkin
              belirli veya yaklaşık konum bilgileri İşlem Güvenliği Bilgisi: log
              in credential bilgileri, cihaz bilgileri, IP adresi Pazarlama
              Bilgisi: alışkanlıklarınız ile beğenilerinizi gösteren raporlar ve
              değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları
              vb. bilgiler Talep/Şikâyet Yönetimi Bilgisi: Uygulama üzerinden
              yaptığınız talep ve şikayetler ile Uygulama’da paylaştığınız
              yorumlarınız Kişilerin ırkı, etnik kökeni, siyasi düşüncesi,
              felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve
              kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel
              hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri
              ile biyometrik ve genetik verileri özel nitelikli kişisel veridir.
              Manjo herhangi bir şekilde özel nitelikli kişisel verilerinizi
              toplamaz ve işlemez.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              2. Kişisel Verilerin İşlenme Amacı
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Yukarıda belirtilen kişisel verileriniz aşağıdaki amaçlar
              doğrultusunda ve bunlarla sınırlı ve ölçülü olacak şekilde
              işlenecektir: Kullanıcılar ile iletişime geçmek ve müşteri
              ilişkilerini yönetmek, Sipariş ve ödemelerin alınması ile
              siparişlerin teslim edilmesi süreçlerini yürütmek, Tercih, beğeni,
              ilgi alanları, kullanım alışkanlıklarınıza ve bulunduğunuz
              lokasyona en uygun ürün seçeneklerini sunmak; size en uygun
              ürünler ve fırsatlar hakkında bilgilendirme yapmak, Manjo
              restoranları, üye işyerleri ve Kullanıcılar arasındaki iletişimi
              yürütmek, Ürün ve hizmetlerimizi geliştirmek için analizler
              gerçekleştirmek, Müşteri memnuniyetine yönelik aktiviteler
              yürütmek, Manjo’nun sunduğu ürün ve hizmetleri tanıtmak; satış ve
              pazarlama faaliyetlerini yürütmek, Ürün/hizmetlerimizin
              iyileştirilmesine yönelik geri bildirimleri almak ve
              değerlendirmek, Talep/şikayetlerin takibini sağlamak ve
              sonuçlandırmak, Faaliyetlerimizi mevzuata uygun olarak yürütmek,
              İş faaliyetlerimizin incelenmesi ve denetlenmesi, Ödemelere
              ilişkin finans ve muhasebe işlerini yürütmek.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              3. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Kişisel verileriniz Manjo tarafından e-posta, ilgili internet
              siteleri ve mobil uygulama gibi kanallardan, Manjo’nun erişimine
              imkan verdiğiniz sosyal medya hesapları üzerinden elektronik
              ortamda ve çağrı merkezi kanalı ile veya fiziki olarak posta/kargo
              yolu ile toplanmaktadır.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Kişisel verileriniz işbu Aydınlatma Metni’nin 2. maddesinde
              belirtilen amaçlar doğrultusunda Kanun'un 5. maddesinde belirtilen
              aşağıdaki hukuki sebeplere dayanılarak işlenebilecektir:
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kanunlarda açıkça öngörülmesi,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Sizinle aramızdaki sözleşmenin kurulması veya ifasıyla doğrudan
              doğruya ilgili olması kaydıyla, size ait kişisel verilerinizi
              işlenmesinin gerekli olması,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Hukuki yükümlülüklerimizi yerine getirebilmemiz için zorunlu
              olması,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Sizin tarafınızdan alenileştirilmiş olması (alenileştirme
              amacınızla bağdaştığı ölçüde),
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Bir hakkın tesisi, kullanılması veya korunması için kişisel
              verilerinizi işlemenin zorunlu olması,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Temel hak ve özgürlüklerinize zarar vermemek kaydıyla, meşru
              menfaatlerimiz için zorunlu olması.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              4. Kişisel Verilerin Aktarılması Manjo tarafından toplanan kişisel
              verileriniz yukarıda Aydınlatma Metni’nin 2. maddesinde detayları
              verilen amaçlarla ve 3. maddede belirtilen hukuki sebeplere
              dayanılarak Kanun'un 8. maddesi uyarınca yurt içindeki
              iştiraklerimiz, hissedarlarımız, iş ortaklarımız, kanunen yetkili
              kamu kurumları ve özel kişiler ile paylaşılabilecektir. Aynı
              amaçlar doğrultusunda ve bunlarla sınırlı olarak kişisel
              verileriniz açık rızanız ile Kanun’un 9. maddesi uyarınca
              yurtdışındaki iştiraklerimiz, hissedarlarımız ve iş ortaklarıyla
              paylaşılabilecektir.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              5. Kişisel Verilere İlişkin Haklarınız
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Kanun’un 11. maddesi kapsamında kişisel verilerinize ilişkin
              olarak sizlere aşağıda belirtilen haklar tanınmıştır:
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kişisel verilerinizin işlenip işlenmediğini öğrenme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kişisel verilerinin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kişisel verilerinizin üçüncü kişilere aktarılıp aktarılmadığını
              öğrenme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kişisel verileri üçüncü kişilere aktarılıyorsa, yurt içinde veya
              yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
              kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
              isteme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
              olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
              kalkması hâlinde kişisel verilerinizin silinmesini veya yok
              edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
              verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
              analiz edilmesi suretiyle kişinin kendi aleyhine bir sonucun
              ortaya çıkmasına itiraz etme,
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              - Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
              zarara uğramanız hâlinde zararın giderilmesini talep etme.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Manjo’nun işbu Aydınlatma Metni’ni, iş gereksinimleri, kanuni
              gereksinimler doğması halinde tek taraflı olarak güncelleme hakkı
              saklıdır. Bu metnin en güncel haline ulaşmak için ana sayfamızı
              düzenli olarak ziyaret etmenizi öneririz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Manjo ile paylaştığınız kişisel verilerinizin işlenmesine ilişkin
              daha detaylı bilgiye web sitemizde yer alan Manjo Kişisel
              Verilerin Korunması (“KVK”) ve Gizlilik Politikası’ndan
              erişebilirsiniz.
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Kişisel verilerinizin korunması ve işlenmesine yönelik her türlü
              sorunuz için info@manjo.app e-posta adresi üzerinden bizimle
              iletişime geçebilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
