/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import {
  ChatAltIcon,
  SparklesIcon,
  ShareIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import Header from "./components/header";
import Footer from "./components/footer";
import axios from "axios";
import React from "react";
import CustomerFAQ from "./components/customers_faq";
import { Link } from "react-router-dom";

console.log(process.env.PUBLIC_URL);

const activity = [
  {
    id: 1,
    type: "comment",
    person: { name: "Eduardo Benz", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    feedImageUrl: process.env.PUBLIC_URL + "/feed-1.png",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ",
    date: "6d ago",
  },
  {
    id: 2,
    type: "comment",
    person: { name: "Hilary Mahy", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    feedImageUrl: process.env.PUBLIC_URL + "/feed-2.png",
    date: "2d ago",
  },
  {
    id: 3,
    type: "comment",
    person: { name: "Jason Meyers", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1518577915332-c2a19f149a75?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1462&q=80",
    feedImageUrl: process.env.PUBLIC_URL + "/feed-3.png",
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.",
    date: "2h ago",
  },
];

const features = [
  {
    name: "Manjo'da listelenin",
    description: "Bulunduğunuz bölgenin potansiyelinin tamamına ulaşın.",
  },
  {
    name: "Sosyal menü",
    description:
      "Müşterileriniz sizden sipariş verince arkadaşlarına haber gönderelim.",
  },
  {
    name: "Sosyal hediyeleşme",
    description: "Müşterileriniz her sipariş ile yeni müşteriler getirsin.",
  },
  {
    name: "İnteraktif QR menü",
    description:
      "Dijital menünüz müşterilerinize güvenli ve temassız bir restoran deneyimi yaşatın.",
  },
  {
    name: "Temassız sipariş",
    description:
      "Müşterileriniz sadece telefonlarını kullanarak menünüzü inceleyip sipariş versinler.",
  },

  {
    name: "Müşteri sadakati",
    description: "Dijital damga kartı ile müşteri sadakatiniz seviye atlasın.",
  },
  {
    name: "Dinamik hazırlama süreleri",
    description:
      "Ürün bazında hazırlama süreleri ayarlayarak müşterinize en doğru bilgiyi verin.",
  },
  {
    name: "Menü düzenleme",
    description:
      "Tek tık ile hazırlama ürün hazırlama sürelerini ve stok durumunu ayarlayın.",
  },
];

const includedFeatures = [
  "Manjo'da listelenme",
  "Ön ödemeli siparişler",
  "Sadakat programı",
  "QR ile sipariş ve ödeme çözümleri",
];

export default function LandingPage() {
  const [post, setPost] = React.useState(null);

  // onSubmit method
  function sendLink(e) {
    console.log(e.target[0].value);
    e.preventDefault();
    let phone = e.target[0].value;
    if (true) {
      axios
        .post(
          "https://37cramiapk.execute-api.eu-central-1.amazonaws.com/dev/contact",
          {
            phone: phone,
          }
        )
        .then((response) => {
          setPost(response.data);
        });
    } else {
      alert("invalid email");
    }
  }

  return (
    <div className="bg-white">
      <Header />

      <main>
        {/* Hero section */}
        <div className="bg-manjOrange-lightest relative pt-16 pb-16 sm:pb-24">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>
                  <h1 className="mt-4 text-3xl tracking-tight font-semibold text-black sm:mt-5 sm:leading-none lg:mt-6 lg:text-4xl xl:text-5xl">
                    <span className="md:block">Manjo ile</span>{" "}
                    <span className="text-manjOrange md:block">
                      Restoranda Sıra Bekleme
                    </span>
                  </h1>
                  <p className="mt-3 text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Yakınınızdaki restoranlarda uzun sıralara girmeden sipariş
                    ver.
                    <br /> Önceden sipariş verip beklemeden teslim al.
                    <br />
                    Sana özel indirimleri yakala ve arkadaşlarına hediyeler
                    gönder.
                  </p>
                  <div className="mt-10 w-full sm:max-w-lg lg:ml-0">
                    <div className="flex flex-wrap items-start">
                      <div className="flex justify-center m-2 ml-0">
                        <a
                          href="https://apps.apple.com/tr/app/manjo-takeout-at-restaurants/id1553676376"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="h-14 sm:h-15"
                            src={process.env.PUBLIC_URL + "/app-store-cta.png"}
                            alt="Tuple"
                          />
                        </a>
                      </div>
                      <div className="flex justify-center m-2 ml-0">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.manjo.Manjo&hl=tr&gl=US"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="h-14 sm:h-15"
                            src={
                              process.env.PUBLIC_URL + "/google-play-cta.png"
                            }
                            alt="Tuple"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8">
                    {post == null ? (
                      <form
                        onSubmit={sendLink.bind(this)}
                        className="mt-4 sm:flex sm:max-w-md"
                      >
                        <input
                          type="text"
                          name="phone-number"
                          id="phone-number"
                          className="focus:ring-manjOrange focus:border-manjOrange block w-full  border-gray-300 rounded-md"
                          placeholder="(555) 987 6543"
                        />
                        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                          <button
                            type="submit"
                            className="w-full bg-manjOrange border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-manjOrange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-manjOrange-dark"
                          >
                            Link Gönder
                          </button>
                        </div>
                      </form>
                    ) : (
                      <span className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                        Telefonuna bak SMS gelmiş mi? 👌
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-8 sm:mt-12 lg:mt-0 lg:col-span-6">
                <div className="sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                  <img
                    src={process.env.PUBLIC_URL + "/app_mock_animated.gif"}
                    alt="Manjo App"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden bg-white">
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-manjOrange border-2 border-yellow-200">
                      <ClockIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Vaktin yoksa bile..
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Manjo ile restoranlara gitmeden hızlıca ve kolayca sipariş
                      ver, sıra bekleme ve zaman kazan. Yoğun anların için bire
                      bir.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="sm:px-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full min-h-120 rounded-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={process.env.PUBLIC_URL + "/time.png"}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center border-2 border-yellow-200 bg-manjOrange ">
                      <SparklesIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Benzersiz ödüller kazanın
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Sadakat programına katılan restoranlardan her siparişinde
                      hem kendin için damga topla hem arkadaşlarına hediye et.
                    </p>
                  </div>
                </div>
              </div>
              <div className="my-12 sm:my-16">
                <div className="px-4 sm:px-6 lg:relative lg:h-full">
                  <img
                    className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={process.env.PUBLIC_URL + "/Manjo-Loyalty.png"}
                    alt="Customer user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-manjOrange border-2 border-yellow-200">
                      <ShareIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Beraberken daha lezzetli
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Arkadaşlarınızın nerelerde yediğini takip et. Beraber ye
                      beraber kazan.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-0 sm:mx-12 mt-12 sm:mt-16 lg:mt-0">
                <div className="sm:px-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <div className="flow-root">
                    <ul className="-mb-8">
                      {activity.map((activityItem, activityItemIdx) => (
                        <li key={activityItem.id}>
                          <div className="relative pb-8">
                            {activityItemIdx !== activity.length - 1 ? (
                              <span
                                className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                              />
                            ) : null}
                            <div className="relative flex items-start space-x-3">
                              {activityItem.type === "comment" ? (
                                <>
                                  <div className="relative">
                                    <img
                                      className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                      src={activityItem.imageUrl}
                                      alt=""
                                    />

                                    <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                      <ChatAltIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <img
                                      src={activityItem.feedImageUrl}
                                      alt=""
                                      className=""
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-manjOrange-shallow">
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block text-manjOrange">
                Manjolamaya hazır mısın?
              </span>
              <span className="block">Hemen uygulamamızı indir!</span>
            </h2>
            <div className="mt-8 flex justify-center">
              <div className="flex justify-center pr-2">
                <a
                  href="https://apps.apple.com/tr/app/manjo-takeout-at-restaurants/id1553676376"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="h-14 sm:h-15"
                    src={process.env.PUBLIC_URL + "/app-store-cta.png"}
                    alt="Tuple"
                  />
                </a>
              </div>
              <div className="flex justify-center pl-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.manjo.Manjo&hl=tr&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="h-14 sm:h-15"
                    src={process.env.PUBLIC_URL + "/google-play-cta.png"}
                    alt="Tuple"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
