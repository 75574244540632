/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import {
  ChatAltIcon,
  CheckIcon,
  LightningBoltIcon,
  SparklesIcon,
  TrendingUpIcon,
  ShareIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  StarIcon,
} from "@heroicons/react/outline";
import Header from "./components/header";
import Footer from "./components/footer";
import axios from "axios";
import React from "react";
import LogoCloud from "./components/restaurant_logo_cloud";
import Logos from "./components/restaurant_logo_cloud";
import { Link } from "react-router-dom";

const activity = [
  {
    id: 1,
    type: "comment",
    person: { name: "Eduardo Benz", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    feedImageUrl: process.env.PUBLIC_URL + "/feed-1.png",
    date: "6d ago",
  },
  {
    id: 2,
    type: "comment",
    person: { name: "Hilary Mahy", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    feedImageUrl: process.env.PUBLIC_URL + "/feed-2.png",
    date: "2d ago",
  },
  {
    id: 3,
    type: "comment",
    person: { name: "Jason Meyers", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1518577915332-c2a19f149a75?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1462&q=80",
    feedImageUrl: process.env.PUBLIC_URL + "/feed-3.png",
    date: "2h ago",
  },
];

const features = [
  {
    name: "Manjo'da listelenin",
    description: "Bulunduğunuz bölgenin potansiyelinin tamamına ulaşın.",
  },
  {
    name: "Sosyal menü",
    description:
      "Müşterileriniz sizden sipariş verince arkadaşlarına haber gönderelim.",
  },
  {
    name: "Sosyal hediyeleşme",
    description: "Müşterileriniz her sipariş ile yeni müşteriler getirsin.",
  },
  {
    name: "İnteraktif QR menü",
    description:
      "Dijital menünüz ile müşterilerinize güvenli ve temassız bir restoran deneyimi yaşatın.",
  },
  {
    name: "Temassız sipariş",
    description:
      "Müşterileriniz sadece telefonlarını kullanarak menünüzü inceleyip sipariş versinler.",
  },

  {
    name: "Müşteri sadakati",
    description: "Dijital damga kartı ile müşteri sadakatiniz seviye atlasın.",
  },
  {
    name: "Dinamik hazırlama süreleri",
    description:
      "Ürün bazında hazırlama süreleri ayarlayarak müşterinize en doğru bilgiyi verin.",
  },
  {
    name: "Menü düzenleme",
    description:
      "Tek tık ile ürün hazırlama sürelerini ve stok durumunu ayarlayın.",
  },
];

const includedFeatures = [
  "Manjo'da listelenme",
  "Ön ödemeli siparişler",
  "Sadakat programı",
  "QR ile sipariş ve ödeme çözümleri",
];

export default function RestaurantsLandingPage() {
  const [post, setPost] = React.useState(null);

  // onSubmit method
  function registerEmail(e) {
    console.log(e.target[0].value);
    e.preventDefault();
    let email = e.target[0].value;
    if (true) {
      axios
        .post(
          "https://37cramiapk.execute-api.eu-central-1.amazonaws.com/dev/contact",
          {
            email: email,
          }
        )
        .then((response) => {
          setPost(response.data);
        });
    } else {
      alert("invalid email");
    }
  }

  return (
    <div className="bg-white">
      <Header />
      <main>
        <div className="bg-manjOrange-light pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div>
                <Link to="/">
                  <img
                    className="h-11 w-auto"
                    src={process.env.PUBLIC_URL + "/manjo1.png"}
                    alt="Workflow"
                  />
                </Link>
              </div>
              <div className="mt-20">
                <div className="mt-6 sm:max-w-xl">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Markanızın Potansiyelini Ortaya Çıkarın
                  </h1>
                  <p className="mt-6 text-xl text-gray-800">
                    Manjo uygulaması ile bulunduğunuz bölgedeki insanlara
                    ulaşın. Ön ödemeli yemek siparişleri alın ve satışlarınızı
                    artırın.
                  </p>
                </div>
                {post == null ? (
                  <form
                    onSubmit={registerEmail.bind(this)}
                    className="mt-12 sm:max-w-lg sm:w-full sm:flex"
                  >
                    <div className="min-w-0 flex-1">
                      <label htmlFor="hero-email" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="hero-email"
                        type="email"
                        className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-manjOrange focus:ring-manjOrange"
                        placeholder="Email adresinizi girin"
                      />
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-3">
                      <button
                        type="submit"
                        className="bg-viomanjo block w-full rounded-md border border-transparent px-5 py-3 bg-rose-500 text-base font-medium text-white shadow hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
                      >
                        Bize ulaşın
                      </button>
                    </div>
                  </form>
                ) : (
                  <span className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                    Size en kısa zamanda geri dönüş yapacağız 👌
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="hidden sm:block">
                <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                <svg
                  className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                  />
                </svg>
              </div>
              <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                  src={process.env.PUBLIC_URL + "/panel_screenshot.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* Hero section */}

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden bg-manjOrange-shallow">
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-manjOrange border-2 border-yellow-200">
                      <LightningBoltIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Sosyal hediyeler ve referanslar ile markanızı büyütün
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Müşterileriniz birbirlerine markanızı taşıyan sadakat
                      puanları ve hediyeler göndersinler.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="sm:px-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={process.env.PUBLIC_URL + "/Manjo-SSs3.png"}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center border-2 border-yellow-200 bg-manjOrange ">
                      <TrendingUpIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Verimliliğinizi artırın
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Dijital siparişler ve online ödemeler ile verimliliğinizi
                      artırın. Yoğun zamanlarda işletmenizde oluşan sıraların
                      sebep olduğu zararların önüne geçin.
                    </p>
                  </div>
                </div>
              </div>
              <div className="my-12 sm:my-16">
                <div className="px-4 sm:px-6 lg:relative lg:h-full">
                  <img
                    className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={process.env.PUBLIC_URL + "/Manjo-Transformation.png"}
                    alt="Customer user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-manjOrange border-2 border-yellow-200">
                      <ShareIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Müşterileriniz dijital takipçileriniz olsun
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Müşterilerinizin arkadaşlarının ne sipariş ettiğini
                      görmelerini sağlayın. Arkadaşları restoranınızdan sipariş
                      verdiğinde onları bilgilendirelim.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-0 sm:mx-12 mt-12 sm:mt-16 lg:mt-0">
                <div className="sm:px-6 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <div className="flow-root">
                    <ul className="-mb-8">
                      {activity.map((activityItem, activityItemIdx) => (
                        <li key={activityItem.id}>
                          <div className="relative pb-8">
                            {activityItemIdx !== activity.length - 1 ? (
                              <span
                                className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                              />
                            ) : null}
                            <div className="relative flex items-start space-x-3">
                              {activityItem.type === "comment" ? (
                                <>
                                  <div className="relative">
                                    <img
                                      className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                      src={activityItem.imageUrl}
                                      alt=""
                                    />

                                    <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                      <ChatAltIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <img
                                      src={activityItem.feedImageUrl}
                                      alt=""
                                      className=""
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center border-2 border-yellow-200 bg-manjOrange ">
                      <SparklesIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Sadık müşterilerinizi ödüllendirin
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      Sosyal özelliklerden güç alarak çalışan sadakat programı
                      ile müşterileriniz her siparişte yeni bir müşteri
                      getirsin. Markanız organik olarak büyüsün.
                    </p>
                  </div>
                </div>
              </div>
              <div className="my-12 sm:my-16">
                <div className="px-4 sm:px-6 lg:relative lg:h-full">
                  <img
                    className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={process.env.PUBLIC_URL + "/Manjo-Loyalty.png"}
                    alt="Customer user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Logos />

        {/*Features*/}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div>
              <h2 className="text-base font-semibold text-manjOrange uppercase tracking-wide">
                İşletmenİzİn İhtİyacı olan her şey
              </h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900">
                Kafeler ve restoranlar için geliştirildi
              </p>
              <p className="mt-4 text-lg text-gray-500">
                Yeme içme işletmenizin dijital dönüşümü için ihtiyacı olan her
                şey Manjo'da
              </p>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <CheckIcon
                        className="absolute h-6 w-6 text-green-500"
                        aria-hidden="true"
                      />
                      <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                        {feature.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-9 text-base text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
        {/*Pricing*/}
        {/*
            <div className="bg-gray-100">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                  <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">Gizli masraf yok</h2>
                  <p className="mt-4 text-xl text-gray-600">
                    Herhangi bir taahüt altına girmeden ödeme masrafları ve KDV dahil dahil sipariş başına en fazla %11 ödeyin. 
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
              <div className="relative">
                <div className="absolute inset-0 h-1/2 bg-gray-100" />
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                    <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                      <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Katıl</h3>
                      <p className="mt-6 text-base text-gray-500">
                        Manjo ile bulunduğunuz bölgenin potansiyeline ulaşın ve satışlarınızı artırın.
                      </p>
                      <div className="mt-8">
                        <div className="flex items-center">
                          <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-manjOrange">
                            Dahİl Özellİkler
                          </h4>
                          <div className="flex-1 border-t-2 border-gray-200" />
                        </div>
                        <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                          {includedFeatures.map((feature) => (
                            <li key={feature} className="flex items-start lg:col-span-1">
                              <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                              </div>
                              <p className="ml-3 text-sm text-gray-700">{feature}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                      <div className="mb-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                        <span>₺0</span>
                        <span className="ml-3 text-xl font-medium text-gray-500">TRY</span>
                        <span className="ml-1 text-4xl font-medium text-gray-800">/ay</span>

                      </div>
                      <p className="text-lg leading-6 font-medium text-gray-900">Yeni müşterileriniz için %11 komisyon</p>
                      <p className="mt-4 text-sm">
                        <Link to="mailto:info@manjo.app" className="font-medium text-gray-500 underline">
                          Daha fazlasını öğren
                        </Link>
                      </p>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}

        {/* CTA Section */}
        <div className="bg-white">
          <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Manjo'da yer almaya hazır mısınız?</span>
              <span className="block bg-gradient-to-r from-manjOrange to-manjOrange-dark bg-clip-text text-transparent">
                Detaylı bilgi almak için hemen bizimle iletişime geçin!
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex">
              <Link
                to="mailto:info@manjo.app"
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-manjOrange bg-manjOrange-lightest hover:bg-manjOrange-light"
              >
                Bize ulaşın
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
